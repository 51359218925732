jQuery.fn.tmVideoAutoplay = function () {
    return jQuery(this).find('.js-trailer-trigger').each(function () {
        var videoButton = jQuery(this);
        var videoBtnWrap = videoButton.closest(".js-trailer-trigger-wrap");
        var origURL = videoButton.attr("data-src");

        if (videoButton.data("init-video-autoplay") !== "init") {
            videoButton.on("touchstart mouseenter", function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                videoBtnWrap.addClass("is-playing");

                if (videoBtnWrap.find(".js-video").hasClass("iframe-video")) {
                    setTimeout(function () {
                        if (videoBtnWrap.hasClass("is-playing")) {
                            videoBtnWrap.find(".js-video").attr("src", origURL + "?autoplay=1&mute=1&enablejsapi=1");
                        }
                    }, 500)
                } else {
                    videoBtnWrap.find(".js-video").get(0).play();
                }

                return;
            });

            videoButton.on("mouseleave touchmove", function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                videoBtnWrap.removeClass("is-playing");

                if (videoBtnWrap.find(".js-video").hasClass("iframe-video")) {
                    setTimeout(function () {
                        videoBtnWrap.find(".js-video").attr("src", "");
                    }, 500)
                } else {
                    videoBtnWrap.find(".js-video").get(0).stop();
                }
            });
        }
    })
}

$(function () {
    jQuery('body').tmVideoAutoplay(['init-video-autoplay']);
});