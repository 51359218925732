jQuery.fn.tmPhoneNumberCheck = function () {
    return jQuery(this).find('.js-check-phone-number').on("change", function () {
        function isValidPhoneNumber(phoneNumber) {
            // Use a regular expression to match the format 999 999 999
            var phonePattern = /^\d{3} \d{3} \d{3}$/;
            return phonePattern.test(phoneNumber);
        }

        var input =  $(this);
        var phoneNumberInput = input.val();
        var closestForm = input.closest("form")

        console.log(phoneNumberInput)

        // Check if the input is not empty
        if (phoneNumberInput.trim() === '') {
           // console.log('Please enter a phone number.');
           closestForm.find(".btn").addClass("btn-disabled")
        } else if (!isValidPhoneNumber(phoneNumberInput)) {
            closestForm.find(".btn").addClass("btn-disabled")
           // console.log('Invalid phone number format. Use 999 999 999.');
        } else {
            closestForm.find(".btn").removeClass("btn-disabled")
           // console.log('Phone number is valid!');
        }
    })
}

$(function () {
    jQuery('body').tmPhoneNumberCheck(['init-phone-number-check']);
})