jQuery.fn.tmMagentaCalculator = function () {
    return jQuery(this).find('.js-magenta-calculator').each(function () {
        var input = $(this);
        var value = input.val();
        var hrefHeight = $(".js-magenta-calc-modal").innerHeight();
        var delayLinkVal = 10;

        $(".js-magenta-calc-modal-wrap").css("height", hrefHeight)

        function getTheNumbers() {
            if (value == 0) {
                $(".js-magenta-price-first").html("526,50");
                $(".js-magenta-price-second").html("837,00");

                $(".js-magenta-calc-modal").removeClass("active");

                setTimeout(function () {
                    $(".js-magenta-calc-modal[data-attr='dio']").addClass("active");
                }, delayLinkVal)
            } else if (value == 1) {
                $(".js-magenta-price-first").html("497,25");
                $(".js-magenta-price-second").html("790,50");


                $(".js-magenta-calc-modal").removeClass("active");

                setTimeout(function () {
                    $(".js-magenta-calc-modal[data-attr='tria']").addClass("active");
                }, delayLinkVal)
            } else if (value == 2) {
                $(".js-magenta-price-first").html("468,00");
                $(".js-magenta-price-second").html("744,00");

                $(".js-magenta-calc-modal").removeClass("active");

                setTimeout(function () {
                    $(".js-magenta-calc-modal[data-attr='tesera']").addClass("active");
                }, delayLinkVal)

            } else if (value == 3) {
                $(".js-magenta-price-first").html("438,75");
                $(".js-magenta-price-second").html("697,50");

                $(".js-magenta-calc-modal").removeClass("active");

                setTimeout(function () {
                    $(".js-magenta-calc-modal[data-attr='pende']").addClass("active");
                }, delayLinkVal)
            }
        }

        getTheNumbers()

        input.on("change", function () {
            value = input.val();

            getTheNumbers();
        })
    });
}

$(function () {
    jQuery('body').tmMagentaCalculator(['init-magenta-calculator']);
});