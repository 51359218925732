jQuery.fn.tmTtlDropdownC = function () {
    return jQuery(this).find('.js-ttl-dropdown-c').each(function () {
        var element = jQuery(this);

        if (element.data("init-ttl-dropdown-c") !== "init") {
            element.on("click", function () {
                element.closest(".js-ttl-dropdown-wrap").toggleClass("dp-is-open")
            });

            

            jQuery(".js-ttl-dropdown-link").on("click", function () {
                var ttlLink = jQuery(this);
                var hwName = ttlLink.closest("li").data("hw-name");

                ttlLink.closest(".js-ttl-dropdown-wrap").find(".ttl-dropdown-text").html(hwName);
                ttlLink.closest(".js-ttl-dropdown-wrap").removeClass("dp-is-open");
            });
        }
    })
}
jQuery.fn.tmTtlDropdownPosition = function () {
    return jQuery(this).find('.js-dropdown-ghost-wrap').each(function () {
        var element = jQuery(this);

        if (element.data("init-ttl-dropdown-position") !== "init") {
            setTimeout(function(){
                var row = element.closest(".js-dropdown-ghost-row")
                var flexiElement = row.find(".js-slideshow-v2").innerHeight();
                var tabField = element.closest(".js-tabs-field");
                var tabListC = tabField.find(".js-tabs-list-c");
                var gaps = 45;

                if(window.matchMedia("(min-width: 768px)").matches) {
                    var gaps = 90;
                }
    
                if(window.matchMedia("(max-width: 991.98px)").matches) {
                    tabListC.css("margin-top", flexiElement + gaps)
                }
            }, 200)
        }
    })
}
$(function () {
    jQuery('body').tmTtlDropdownC(['init-ttl-dropdown-c']);
    jQuery('body').tmTtlDropdownPosition(['init-ttl-dropdown-position']);

    $(window).on('scroll', function () {
        jQuery('body').tmTtlDropdownPosition(['init-ttl-dropdown-position']);
    });
});