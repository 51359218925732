jQuery.fn.tmtopUpForm = function () {
    return jQuery(this).find('.js-tuc-item').on("click", function () {
        var element = jQuery(this);
        var elementWrap = element.closest(".js-top-up-component");
        var topUpSum = element.attr("data-tup-up-price");

        elementWrap.children(".js-tuc-item").removeClass("is-active");

        element.addClass("is-active");
        $(".js-price-input").val(topUpSum)

        if (element.hasClass("js-tuc-item-extra")) {
            var dataTarget = element.attr("data-toggle-target");

            $(dataTarget).collapse('toggle');
        } else {
            $(".js-custom-amount").removeClass("in").css("style", 0)
        }
    })
}
$(function () {
    jQuery('body').tmtopUpForm(['init-top-up-form']);
});