jQuery.fn.tmFixedCtaPanel = function () {
    return jQuery(this).find('.js-fixed-panel').each(function () {
        var element = jQuery(this);

        if (element.data("init-fixed-cta-panel") !== "init") {
            if ($(window).scrollTop() > 600) {
                $(".js-fixed-panel").addClass("show");
            } else {
                $(".js-fixed-panel").removeClass("show");
            }
        }
    })
}

$(function () {
    $(window).scroll(function () {
        jQuery('body').tmFixedCtaPanel(['init-fixed-cta-panel']);
    });
});