jQuery.fn.tmProgressBarAnimSlider = function () {
    return jQuery(this).find('.js-progress-bar-for-slider').each(function () {

        var element = jQuery(this);

        if (element.data("init-progress-bar-anim-slider") !== "init") {
            var closestSection = element.closest(".bs-section")
            var wscroll = 0;
            var wscroll = $(window).scrollTop();

            var value = element.attr('aria-valuenow');
            var valueMax = element.attr('aria-valuemax');

            var animDuration = element.attr("data-animationDuration");

            if (wscroll >= closestSection.offset().top && element.width() === 0) {
                if (value !== undefined && element.closest(".slick-slide").hasClass("slick-active") && !element.hasClass("is-finished")) {
                    element.animate({
                        width: (value + '%')
                    }, animDuration * 1000)
                }

                setTimeout(function () {
                    if (element.css("width") >= valueMax) {
                        element.stop(true);
                        element.addClass('is-finished')
                    }
                }, animDuration * 970)
            }
        }
    })
}

$(function () {
    jQuery('body').tmProgressBarAnimSlider(['init-progress-bar-anim-slider']);

    $(window).on('scroll', function () {
        jQuery('body').tmProgressBarAnimSlider(['init-progress-bar-anim-slider']);
    })

    $('.js-speedy-slider').on('afterChange', function (event, slick, currentSlide) {
        // Your custom code to be executed when the slider changes
        setTimeout(function() {
            jQuery('body').tmProgressBarAnimSlider(['init-progress-bar-anim-slider']);
        }, 500)
    });
});