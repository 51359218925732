jQuery.fn.tmXmasSlider = function () {
    return jQuery(this).find('.js-xmas-slider').each(function () {
        var element = jQuery(this);

        if (element.data("init-xmas-slider") !== "init") {
            if (element.find(".slider-item").length > 1) {
                setTimeout(function () {
                    element.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        accessibility: false,
                        infinite: false,
                        speed: 300,
                        responsive: [{
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1,
                                    variableWidth: true,
                                    dots: true
                                }
                            }
                        ]
                    });
                }, 50);

                setTimeout(function () {
                    element.find(".slick-track").addClass("flex row-boxes");
                }, 100);

                if (window.matchMedia("(min-width: 768px)").matches) {
                    element.on('init', function (event) {
                        var dots = element.find('.slick-dots li');
                        dots.each(function (k) {
                            $(this).find('button').addClass('heading' + k);
                        });

                        var items = element.find(".slider-item");
                        items.each(function (k) {
                            var text = $(this).data("dot-title");
                            
                            dots.find("button.heading" + k).text(text);
                        });
                    });
                }
            }
        }
    })
}

$(function () {
    $(window).on("load", function () {
        setTimeout(function () {
            jQuery('body').tmXmasSlider(['init-xmas-slider']);
        }, 1000);
    });
})