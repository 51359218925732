//ESSENTIAL INIT

registerInitFunction([
    jQuery.fn.tmVideoPlayButton,
    jQuery.fn.tmShowMoreWrap,
    jQuery.fn.tmPopOverCustom,
    jQuery.fn.tmModalCustom,
    jQuery.fn.tmHighlightArea,
    jQuery.fn.tmClickAnchorWithFocus,
    jQuery.fn.tmModalCollapseLink,
    jQuery.fn.tmGetFocusSelectAll,
    jQuery.fn.tmResizableImageWidth,
    jQuery.fn.tmFixedCtaPanel,
    jQuery.fn.tmTabLinkActiveScroll,
    jQuery.fn.tmActiveLinkOnScroll,
    jQuery.fn.tmSwitchContent,
    jQuery.fn.tmSwitchContentAttribute,
    jQuery.fn.tmAnchorNavigation,
    jQuery.fn.tmGetStickySideBar,
    jQuery.fn.tmFilterSliderXS,
    jQuery.fn.tmTabsXsV1,
    jQuery.fn.tmCheckoutCollapse,
    jQuery.fn.tmTarifSliderXSV1,
    jQuery.fn.tmTarifSliderV3,
    jQuery.fn.tmTarifSliderV4,
    jQuery.fn.tmTarifSliderV5,
    jQuery.fn.tmPhoneNumberCheck
], 'essential');

$(function () {
    var themeChild = themeChild || {};
    svgTimeStamp = new Date();
    var actualTimeStamp = svgTimeStamp.getTime();
    themeChild.pathToSVGSprite = 'https://cdn.t-mobile.cz/tmcz-2020/images/custom/svg-sprite.svg?t=' + actualTimeStamp + '';

    if (location.host === 'localhost:8080' || location.hostname === '127.0.0.1') {

        if (document.body.dataset !== undefined && document.body.dataset.themeImagesFolder !== undefined) { // read full path to SVG sprite from data attribute
            themeChild.pathToSVGSprite = document.body.dataset.themeImagesFolder + themeChild.pathToSVGSprite;
        } else {
            themeChild.pathToSVGSprite = '/tmcz-child-theme/images' + themeChild.pathToSVGSprite; // if data attribute does not exist, set default path
        }

        themeChild.pathToSVGSprite = '' + document.body.dataset.themeImagesFolder + '/custom/svg-sprite.svg?t=' + actualTimeStamp + ''
    }

    else if (location.hostname === 'templatesdevel.web.cz.tmo') {
        themeChild.pathToSVGSprite = 'https://templatesdevel.web.cz.tmo/tmcz-2020/images/custom/svg-sprite.svg?t=' + actualTimeStamp + ''
    }
    var ajax = new XMLHttpRequest();
    svgTimeStamp = new Date();
    ajax.open("GET", themeChild.pathToSVGSprite, true);
    ajax.send();
    ajax.onload = function (e) {
        var div = document.createElement("div");
        div.innerHTML = ajax.responseText;
        document.body.insertBefore(div, document.body.childNodes[0]);
        div.classList.add("js-svgsprite-2020");

        setTimeout(function () {
            jQuery('body').tmInit(['essential', 'eshop', 'portal']);
        }, 500)
    }

    /*
          $("video.connect-bg source").each(function () {
              var sourceFile = $(this).attr("data-src");
              $(this).attr("src", sourceFile);
              var video = this.parentElement;
              video.load();
              video.play();
          });
      */

    function update_offsets(lh) {
        $('.js-lazy-bg').each(function () {
            var x = $(this).offset().top;
            lh.push(x);
        });
    };

    function lazy(lh) {
        var wscroll = 0;
        var wh = $(window).height();
        wscroll = $(window).scrollTop();

        for (i = 0; i < lh.length; i++) {
            if (lh[i] <= wscroll + (wh - 10)) {

                var bgElement = $('.js-lazy-bg').eq(i);

                if (bgElement.attr("data-lazy-bg-all") && window.matchMedia("(min-width: 320px)").matches) {
                    var imgUrlAll = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-all");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlAll + ')');
                } else if (bgElement.attr("data-lazy-bg-xl-up") && window.matchMedia("(min-width: 1200px)").matches) {
                    var imgUrlxl = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-xl-up");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlxl + ')');
                } else if (bgElement.attr("data-lazy-bg-lg-up") && window.matchMedia("(min-width: 992px)").matches) {
                    var imgUrllg = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-lg-up");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrllg + ')');
                } else if (bgElement.attr("data-lazy-bg-md-up") && window.matchMedia("(min-width: 768px)").matches) {
                    var imgUrlMd = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-md-up");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlMd + ')');
                } else if (bgElement.attr("data-lazy-bg-xs") && window.matchMedia("(max-width: 767.97px)").matches) {
                    var imgUrlxs = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-xs");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlxs + ')');
                } else if (bgElement.attr("data-lazy-bg-sm") && window.matchMedia("(min-width: 560px)").matches) {
                    var imgUrlSm = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-sm");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlSm + ')');
                }
                else if (bgElement.attr("data-lazy-bg-xs-only") && window.matchMedia("(max-width: 559.97px)").matches) {
                    var imgUrlxsOnly = $('.js-lazy-bg').eq(i).attr("data-lazy-bg-xs-only");
                    $('.js-lazy-bg').eq(i).css('background-image', 'url(' + imgUrlxsOnly + ')');
                }
            };
        };
    };

    function BgHeightResize() {
        $(".js-bg-height-resize").each(function () {
            var element = $(this);

            var height = element.innerHeight();

            element.closest(".js-bg-height-resize-wrap").css("height", height)
        })
    }

    // Page Load
    setTimeout(function () {
        var lh = [];
        update_offsets(lh);
        lazy(lh);
    }, 1150)

    $(window).on('scroll', function () {
        var lh = [];
        update_offsets(lh);
        lazy(lh);
    });

    BgHeightResize();

    $(window).on("resize", function () {
        var lh = [];
        lazy(lh);
        jsGridSlider();
        BgHeightResize();
    });

    if ($(".js-range-calculation").length > 0) {
        $(".js-range-calculation").each(function () {
            var range = $(this);

            range.on("input", function () {
                var rangeValue = range.val();

                $(".js-benefits-list").attr("data-range", rangeValue);
            })
        })
    }

    $(".js-special-tarif-offer").each(function () {
        var element = $(this);

        element.closest(".service-listing").addClass("special-tarif-offer");
    });

    $(".js-one-click").on("click", function () {
        var element = $(this);

        setTimeout(function () {
            element.addClass("d-none")
        }, 400)
    });

    $(document).on("click", ".js-switch-modal-content", function (e) {
        e.preventDefault();
        var element = $(this);

        element.closest(".js-modal-content-show").toggleClass("is-hidden");
    });

    $(document).on('click', '.js-switch-modal', function (event) {
        $(".js-switch-modal").closest(".bootbox").next(".modal-backdrop").remove();
        $(".js-switch-modal").closest(".bootbox").remove();
    });

    $(".js-btn-collapse").on("click", function (e) {
        e.preventDefault();

        var element = $(this);

        element.toggleClass("btn-collapse-active");
        $(".js-content-collapse").toggleClass("collapse-active");
        $(".js-left-content-collapse").toggleClass("col-md-4 col-xl-3 col-md-1 col-xl-1");
        $(".js-right-content-collapse").toggleClass("col-md-11 col-xl-11");
    });

    $(".js-collapse-code").on("click", function (e) {
        e.preventDefault();

        var element = $(this);

        element.closest(".js-code-container").toggleClass("code-collapse-active");
    });

    $(".js-clipboard").on("click", function () {
        var element = $(this);

        element.addClass("clipboard-copied");

        setTimeout(function () {
            element.removeClass("clipboard-copied");
        }, 1800)
    });

    $(".tab-list-link").on("click", function () {
        jsCode();
    });

    $(".js-link-open-text").on("click", function (e) {
        e.preventDefault();
        var item = $(this);

        item.closest(".js-text-wrap").toggleClass("text-is-open");
    })

    $(".js-alert-close").each(function () {
        alertObject = $(this);

        alertObject.on("click", function (e) {
            e.preventDefault();

            alertObject.closest(".js-alert-wrap").addClass("d-none alert-is-not-active")
        })
    })

    function jsCode() {
        $(".js-code").each(function () {
            var element = $(this);
            var elementHeight = element.height();

            if (elementHeight >= 200) {
                element.addClass("To-much-babz");
            }
        });
    }

    $(".js-bootstrap-select-custom .dropdown-menu li").on("click", function () {
        var element = $(this);
        var elementText = element.find("a").text();
        var elementWrap = element.closest(".js-bootstrap-select-custom");

        elementWrap.find(".filter-option").removeClass("color-grey-v4")
        elementWrap.find(".filter-option").html(elementText)
    });

    $(".js-progress-bar-in").each(function () {
        var element = $(this);

        element.width(0);

        var value = element.attr('aria-valuenow')

        $(".js-link-active-progress-bar").on("click", function () {
            if (element.hasClass("animate-me")) {
                setTimeout(function () {
                    if (value !== undefined) {
                        element.width(value + '%');
                    }
                }, 500)
            }
        });

        $(".js-show-more-button").on("click", function () {
            setTimeout(function () {
                if (value !== undefined) {
                    element.width(value + '%');
                }
            }, 500)
        });
    })
});

function jsGridSlider() {
    $(".js-grid-slider").each(function () {
        var slider = $(this);
        var sliderItem = slider.find(".grid-list-item:first-child").innerWidth();

        if (sliderItem <= 255) {
            slider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                variableWidth: true,
                infinite: false,
                speed: 300,
                prevArrow: '<div class="grid-slider-navigation prev"><button type="button" class="slick-arrow slick-prev">Previous</button></div>',
                nextArrow: '<div class="grid-slider-navigation next"><button type="button" class="slick-arrow slick-next">Next</button></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
                ]
            });
        } else if (sliderItem >= 260 && window.matchMedia("(min-width: 1645px)").matches) {
            //slider.slick('unslick');
        }
    });
}

function faqFromUrl() {
    var url = window.location.href.toLowerCase();
    var urlOriginal = window.location.href;
    
    if (url.includes('faq')) {
        var targetId = urlOriginal.split('#')[1];
    
        // Select the anchor element by its exact case-insensitive href attribute
        var $targetLink = $('a[href="#' + targetId + '"]');
    
        if ($targetLink.length > 0) {            
            $targetLink.trigger('click');

            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: $('#' + targetId).closest(".panel-faq").offset().top - 62
                }, 800);
            }, 1500)
        } else {
            console.error('Target link not found.'); // Log an error if the link is not found
        }
    } 
} 

function equalheight() {
    var maxHeight = -1;

    $('.js-programs-field').each(function () {
        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });

    $('.js-programs-field').each(function () {
        $(this).height(maxHeight);
    });
}

$(window).on('load', function () {
    if (window.matchMedia("(min-width: 768px)").matches) {
        jsGridSlider();
    }

    equalheight();

    faqFromUrl();
});

$(window).on('resize', function () {
    equalheight();
});