// Define a custom jQuery function to push data to the dataLayer
jQuery.fn.pushCustomDataLayer = function () {
    // Attach a click event listener to elements with the class 'js-data-layer-push'
    return this.find('.js-data-layer-push').on("click", function (e) {
        // Get the current element that was clicked
        var $dataLayer = $(this);

        // Check if the element has the 'data-customPush' attribute
        if (this.hasAttribute('data-customPush')) {
            // Ensure window.dataLayer is initialized as an array if it doesn't already exist
            window.dataLayer = window.dataLayer || [];

            // Parse the JSON data from the 'data-customPush' attribute
            const customData = JSON.parse(this.getAttribute('data-customPush'));

            // Push the customData object to the dataLayer
            window.dataLayer.push(customData);
        } else {
            // Warn in the console if the element doesn't have the 'data-customPush' attribute
            console.warn("You have no data-custom-push attribute.");
        }
    });
}

// Ensure the DOM is fully loaded before running the script
$(function () {
    // Apply the custom function to the body element, so it applies to all '.js-data-layer-push' elements
    jQuery('body').pushCustomDataLayer();
});
