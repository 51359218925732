jQuery.fn.tmAnchorNavigation = function () {
    return jQuery(this).find('.js-anchor-nav-btn').each(function () {
        var element = jQuery(this);

        if (element.data("init-anchor-navigation") !== "init") {
            element.on("click", function(e) {
                e.preventDefault();
                $("html").addClass("anchor-nav-active");
            });
        
            $(document).on("click", ".js-anchor-nav-close, .js-bs-overlay", function(e) {
                e.preventDefault();
                $("html").removeClass("anchor-nav-active");
            });
        
            $(".js-anchor-nav").on("click", ".js-tab-link", function () {
                $("html").removeClass("anchor-nav-active");
            });
        }
    })
}

$(function () {
    jQuery('body').tmAnchorNavigation(['init-anchor-navigation']);
});