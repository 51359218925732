jQuery.fn.tmCheckboxWrap = function () {
    return jQuery(this).find('.js-checkbox-wrap').each(function () {
        var element = jQuery(this);

        if (element.data("init-checkbox-wrap") !== "init") {
            element.on("click", function () {

                var radio = element.find("input[type=radio]");
                var radioInput = $("input[type=radio]");

                if (radio.is(':checked')) {
                    setTimeout(function () {
                        element.addClass("is-active")
                    }, 200)
                } else {
                    $(".js-checkbox-wrap").removeClass("is-active");

                    setTimeout(function(){
                        radioInput.each(function () {
                            var element = $(this);
    
                            if (element.is(':checked')) {
                                element.closest('.js-checkbox-wrap').addClass("is-active")
                            }
                        })
                    },200)
                    
                }
            });
        }
    })
}
$(function () {
    jQuery('body').tmCheckboxWrap(['init-checkbox-wrap']);
});