jQuery.fn.tmFaqOpenLink = function () {
    return jQuery(this).find('.js-open-faq').on("click", function () {
        var link = jQuery(this);
        var linkTarget = link.attr("href");
        
        $(linkTarget).find(".faq-link-grouping").trigger( "click" )
    })
}

$(window).on("load", function () {
    jQuery('body').tmFaqOpenLink(['init-faq-open-link']);
});