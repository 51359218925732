jQuery.fn.tmGetStickySideBar = function () {
    return jQuery(this).find('.js-sticky-content').each(function () {
        var stickyContent = jQuery(this);

        if (stickyContent.data("init-get-sticky-bar") !== "init") {
            if (stickyContent.hasClass("active")) {
                var stickyWrap = stickyContent.closest(".js-sticky-wrapper");
                var stickyHeight = stickyWrap.innerHeight();
                var stickySidebar = stickyContent.find(".js-sticky-sidebar-wrap");
                var stickySidebarHeight = stickyContent.find(".js-sticky-bar").innerHeight();

                var stickyPanelWrap = stickyContent.closest(".js-sticky-panel-wrap");
                var stickyPanel = stickyPanelWrap.find(".js-sticky-panel");

                if (window.matchMedia("(min-width: 992px)").matches) {
                    var stickyHeightFinal = stickyHeight - 147;
                    var stickyPanelHeight = (stickyHeightFinal - stickySidebarHeight) + 87;

                    stickyPanel.css("height", stickyPanelHeight)
                    stickySidebar.css("height", stickyHeightFinal);
                }
            }
        }
    });
}

$(function () {
    $(window).on("resize", function () {
        jQuery('body').tmGetStickySideBar(['init-get-sticky-bar']);
    });

    $(window).on('load', function () {
        jQuery('body').tmGetStickySideBar(['init-get-sticky-bar']);
    });

    $(document).on("click", ".js-show-more-button", function () {
        setTimeout(function () {
            jQuery('body').tmGetStickySideBar(['init-get-sticky-bar']);
        }, 200);
    })

    $(".js-sticky-panel").on("click", ".js-tab-link", function () {
        setTimeout(function () {
            jQuery('body').tmGetStickySideBar(['init-get-sticky-bar']);
        }, 200);

        $('html, body').animate({
            scrollTop: $(".js-sticky-panel-wrap").offset().top
        }, 500);
    });
});