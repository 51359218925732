jQuery.fn.tmCollapseScrollTo = function () {
    return jQuery(this).find('.js-col-scroll-to').each(function () {
        var link = jQuery(this);

        if (link.data("init-collapse-scroll-to") !== "init") {
            $(".js-col-scroll-to").on("click", function () {
                var element = $(this); 
                var eleTarget = element.attr("href");

                setTimeout(function() {
                    $('html, body').animate({
                        scrollTop: $(eleTarget).offset().top
                    }, 2000);
                }, 400)
            });
        }
    })
}

$(function () {
    jQuery('body').tmCollapseScrollTo(['init-collapse-scroll-to']);
});