jQuery.fn.tmHighlightArea = function () {
    return jQuery(this).find('.js-highlight-wrap').each(function () {
        var highlightWrap = jQuery(this);
        var highlightInput = highlightWrap.find(".js-hightlight-area-input");

        if (highlightWrap.data("init-highlight-area") !== "init") {
            highlightInput.data("init-highlight-area", "init").on("click", function (e) {
                $("html").addClass("ha-is-active");
            });

            $(".js-hightlight-area-btn").on("click", function (e) {
                e.preventDefault();

                $('html, body').animate({
                    scrollTop: $(".js-to-scroll").offset().top
                }, 500);

                setTimeout(function () {
                    $("html").addClass("ha-is-active");
                }, 700)
            });


            $(".js-switch-content-btn").on("click", function (e) {
                var element = $(this);

                if( $("html").hasClass("ha-is-active")) {
                    element.closest(".js-switch-content").addClass("is-hidden")
                }
            });


            $(document).on("click", ".js-highlight-close-btn, .js-highlight-overlay", function (e) {
                e.preventDefault();

                $("html").removeClass("ha-is-active");
                $(".js-switch-content").removeClass("is-hidden");
            })
        }
    })
}

$(function () {
    jQuery('body').tmHighlightArea(['init-highlight-area']);
});