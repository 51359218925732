jQuery.fn.tmTafirSliderV1 = function () {
  return jQuery(this).find('.js-tarif-slider-v1').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-v1") !== "init") {
      if (element.find(".tarif-slider-item").length > 3) {
        setTimeout(function () {
          element.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            accessibility: false,
            //variableWidth: true,
            infinite: false,
            speed: 300,
            responsive: [{
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              }
            }
            ]
          });
        }, 50);

        setTimeout(function () {
          element.find(".slick-track").addClass("flex row-boxes");
        }, 100);
      }
    }
  })
}

jQuery.fn.tmTarifSliderV2 = function () {
  return jQuery(this).find('.js-tarif-slider-v2').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-v2") !== "init") {
      if (window.matchMedia("(min-width: 768px)").matches) {
        if (element.find(".tarif-slider-item").length > 3) {
          setTimeout(function () {
            element.slick({
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              accessibility: false,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 3000,
              speed: 300,
              pauseOnFocus: true,
              responsive: [{
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  variableWidth: true,
                  arrows: false,
                  autoplay: false,
                  infinite: false
                }
              }
              ]
            });
          }, 50);

          $('.js-slick-pause').on('click', function () {
            $('.js-tarif-slider-v2').slick('slickPause').slick('slickSetOption', 'autoplay', false);
          });

          $(document).on("hidden.bs.modal", ".js-slick-play-btn.bootbox.modal", function (e) {
            $('.js-tarif-slider-v2').slick('slickPlay').slick('slickSetOption', 'autoplay', true);
          });
        }

      } else if (window.matchMedia("(max-width: 767.98px)").matches) {
        if (element.find(".tarif-slider-item").length >= 2) {
          setTimeout(function () {
            element.slick({
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              accessibility: false,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 3000,
              speed: 300,
              pauseOnFocus: true,
              responsive: [{
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  variableWidth: true,
                  arrows: false,
                  autoplay: false,
                  infinite: false
                }
              }
              ]
            });
          }, 50);

          $('.js-slick-pause').on('click', function () {
            $('.js-tarif-slider-v2').slick('slickPause').slick('slickSetOption', 'autoplay', false);
          });

          $(document).on("hidden.bs.modal", ".js-slick-play-btn.bootbox.modal", function (e) {
            $('.js-tarif-slider-v2').slick('slickPlay').slick('slickSetOption', 'autoplay', true);
          });
        }
      }
    }
  })
}

jQuery.fn.tmTarifSliderV3 = function () {
  return jQuery(this).find('.js-tarif-slider-v3').each(function () {
    var element = jQuery(this);

    function adjustSliderHeight(slider) {
      var sliderList = element.find('.slick-track');

      if (!sliderList.length || element.hasClass("slick-auto-height")) return;

      var sliderListHeight = sliderList.innerHeight();
      setTimeout(function () {
        element.attr("data-slick-height", sliderListHeight)
        sliderList.css('height', sliderListHeight + 'px');
      }, 500);
    }

    if (element.data("init-tarif-slider-v3") !== "init") {
      if (window.matchMedia("(min-width: 1200px)").matches && element.find(".tarif-slider-item").length > 4 || window.matchMedia("(max-width: 1199.98px)").matches && element.find(".tarif-slider-item").length > 2) {
        if (element.attr("data-slick-init") == "true") {
          if (element.hasClass("js-slider-init")) {
            setTimeout(function () {
              element.slick('unslick');
            }, 80)
          }

          setTimeout(function () {
            element.slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: false,
              accessibility: false,
              infinite: false,
              autoplay: false,
              variableWidth: true,
              speed: 300,
              pauseOnFocus: true,
              responsive: [{
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                }
              }
              ]
            });

            element.addClass("js-slider-init");

            element.on('init.slick', function (event, slick) {
              adjustSliderHeight();
            });

            element.on('afterChange.slick', function (event, slick, currentSlide) {
              adjustSliderHeight();
            });

            element.on('setPosition.slick', function (event, slick) {
              adjustSliderHeight();
            });
          }, 120);
        } else {
          setTimeout(function () {
            element.slick({
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: false,
              accessibility: false,
              infinite: false,
              autoplay: false,
              variableWidth: true,
              speed: 300,
              pauseOnFocus: true,
              responsive: [{
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                }
              }
              ]
            });

            element.attr("data-slick-init", "true");

            element.on('init.slick', function (event, slick) {
              adjustSliderHeight();
            });

            element.on('afterChange.slick', function (event, slick, currentSlide) {
              adjustSliderHeight();
            });

            element.on('setPosition.slick', function (event, slick) {
              adjustSliderHeight();
            });
          }, 120);
        }
      } else {
        element.addClass("justify-content-center")
      }
    }
  })
}

jQuery.fn.tmTarifSliderV4 = function () {
  return jQuery(this).find('.js-tarif-slider-v4').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-v4") !== "init") {
      if (!element.hasClass("js-slider-collapse") && element.find(".tarif-slider-item").length > 4 || !element.hasClass("js-slider-collapse") && window.matchMedia("(max-width: 1199.98px)").matches && element.find(".tarif-slider-item").length > 1) {
        element.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          accessibility: false,
          infinite: false,
          autoplay: false,
          speed: 300,
          variableWidth: true,
          responsive: [{
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: false,
              dots: true
            }
          }
          ]
        });
      }
    }
  })
}

jQuery.fn.tmTarifSliderV5 = function () {
  return jQuery(this).find('.js-tarif-slider-v5').each(function () {
    var element = jQuery(this);

    function adjustSliderHeight(slider) {
      var sliderList = element.find('.slick-track');

      if (!sliderList.length || element.hasClass("slick-auto-height")) return;

      var sliderListHeight = sliderList.innerHeight();
      setTimeout(function () {
        element.attr("data-slick-height", sliderListHeight)
        sliderList.css('height', sliderListHeight + 'px');
      }, 500);
    }

    if (element.data("init-tarif-slider-v5") !== "init") {
      if (!element.hasClass("js-slider-collapse") && element.find(".tarif-slider-item").length > 3 || !element.hasClass("js-slider-collapse") && window.matchMedia("(max-width: 991.98px)").matches && element.find(".tarif-slider-item").length > 1) {
        element.slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          accessibility: false,
          infinite: false,
          autoplay: false,
          speed: 300,
          variableWidth: true,
          responsive: [{
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              arrows: false,
              dots: true
            }
          }
          ]
        });

        element.on('init.slick', function (event, slick) {
          adjustSliderHeight();
        });

        element.on('afterChange.slick', function (event, slick, currentSlide) {
          adjustSliderHeight();
        });

        element.on('setPosition.slick', function (event, slick) {
          adjustSliderHeight();
        });
      } else {
        element.addClass("justify-content-center")
      }
    }
  })
}

jQuery.fn.tmTarifSliderV6 = function () {
  return jQuery(this).find('.js-tarif-slider-v6').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-v6") !== "init") {
      if (window.matchMedia("(min-width: 992px)").matches && element.attr("data-slick-init") == "true") {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
      } else if (window.matchMedia("(max-width: 991.98px)").matches) {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
        setTimeout(function () {
          element.slick({
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            accessibility: false,
            variableWidth: true,
            speed: 300
          });
          element.addClass("js-slider-init");
        }, 120);
      }
    }
  })
}

jQuery.fn.tmTarifSliderV7 = function () {
  return jQuery(this).find('.js-tarif-slider-v7').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-v7") !== "init") {
      if (window.matchMedia("(min-width: 992px)").matches && element.attr("data-slick-init") == "true") {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
      } else if (window.matchMedia("(max-width: 991.98px)").matches) {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
        setTimeout(function () {
          element.slick({
            variableWidth: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            accessibility: false,
            speed: 300,
            arrows: false,
            responsive: [{
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            }]
          });
          element.addClass("js-slider-init");
        }, 120);
      }
    }
  })
}

jQuery.fn.tmTarifSliderXSV1 = function () {
  return jQuery(this).find('.js-tarif-slider-xs-v1').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-xs-v1") !== "init") {
      if (window.matchMedia("(min-width: 768px)").matches && element.attr("data-slick-init") == "true") {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
      } else if (window.matchMedia("(max-width: 767.98px)").matches) {
        if (element.hasClass("js-slider-init")) {
          setTimeout(function () {
            element.slick('unslick');
          }, 80)
        }
        setTimeout(function () {
          element.slick({
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            accessibility: false,
            autoplaySpeed: 3000,
            speed: 300
          });
          element.addClass("js-slider-init");
        }, 120);
      }
    }
  })
}

jQuery.fn.tmTarifSliderXS = function () {
  return jQuery(this).find('.js-tarif-slider-xs').each(function () {
    var element = jQuery(this);

    if (element.data("init-tarif-slider-xs") !== "init") {
      if (window.matchMedia("(max-width: 767.98px)").matches) {
        element.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          speed: 300,
          responsive: [{
            breakpoint: 768,
            settings: {
              dots: true,
            }
          }]
        });
      }
    }
  })
}

$(function () {
  $(window).on("load", function () {
    setTimeout(function () {

      $('.js-tarif-slider-v5').on('init', function (event, slick) {
        var element = jQuery(this);

        if (element.hasClass("js-sub-ts-v6")) {
          element.find(".slick-arrow").after("<div class='slick-side-overlay'></div>")
        }
      });

      jQuery('body').tmTafirSliderV1(['init-tarif-slider-v1']);

      jQuery('body').tmTarifSliderV2(['init-tarif-slider-v2']);

      jQuery('body').tmTarifSliderV3(['init-tarif-slider-v3']);

      jQuery('body').tmTarifSliderV4(['init-tarif-slider-v4']);

      jQuery('body').tmTarifSliderV5(['init-tarif-slider-v5']);

      jQuery('body').tmTarifSliderV6(['init-tarif-slider-v6']);

      jQuery('body').tmTarifSliderV7(['init-tarif-slider-v7']);

      jQuery('body').tmTarifSliderXSV1(['init-tarif-slider-xs-v1']);

      jQuery('body').tmTarifSliderXS(['init-tarif-slider-xs']);
    }, 1000);

    $(document).on("click", ".lightbox", function () {
      setTimeout(function () {
        if ($(".bootbox [class*='js-tarif-slider']").length > 0) {

          jQuery('body').tmTafirSliderV1(['init-tarif-slider-v1']);

          jQuery('body').tmTarifSliderV2(['init-tarif-slider-v2']);

          jQuery('body').tmTarifSliderV3(['init-tarif-slider-v3']);

          jQuery('body').tmTarifSliderXSV1(['init-tarif-slider-xs-v1']);
        }
      }, 200)
    });

    $(".js-link-active-slider").on("click", function () {
      var element = $(this);

      if (element.hasClass("collapsed") || element.hasClass("tab-list-link")) {
        $(".js-collapse-slider").slick('unslick');
        $(".js-collapse").removeClass("js-slider-collapse");
        setTimeout(function () {
          jQuery('body').tmTafirSliderV1(['init-tarif-slider-v1']);
          jQuery('body').tmTarifSliderV4(['init-tarif-slider-v4']);
        }, 500)
      }
    });

    $(".js-tabs-scroll").on("click", function () {
      var anchorLink = $(this);
      var eleTarget = anchorLink.attr("href");

      setTimeout(function () {
        $('html, body').animate({
          scrollTop: $(eleTarget).offset().top
        }, 2000);
      }, 400)
    })
  });
})