$(function () {
    $('.js-range-v1').each(function () {
        if ($('.js-range').data('init-range') !== 'init') {
            var $range = $(this),
                id = $range.attr('id'),
                selector = '.range',
                $targetInput = $($range.data('target-input'));

            if ($range.hasClass("theme-v1")) {
                var css = '::-webkit-slider-runnable-track{background:linear-gradient(90deg, #E20074 {value}%, #CCCCDE 0)}';
            }
            else if ($range.hasClass("theme-v2")) {
                var css = '::-webkit-slider-runnable-track{background:linear-gradient(90deg, #e20074 {value}%, #E6E6E6 0)}';
            }
            else if ($range.hasClass("range-v3")) {
                var css = '::-webkit-slider-runnable-track{background:linear-gradient(90deg, #e20074 {value}%, #CCCCCC 0)}';
            }
            else {
                var css = '::-webkit-slider-runnable-track{background:linear-gradient(90deg, #64b9e4 {value}%, #e5e5e5 0)}';
            }
            // if id attribute is not provided, create some random string
            if (id === undefined) {
                id = generateID(5);
                $range.attr('id', 'range-' + id);
                selector = '#range-' + id;
            } else {
                selector += '#' + id;
            }

            $range.before('<style>' + selector + css + '</style>');

            // connect with target input
            if ($targetInput.length) {
                $targetInput.on('input', function () {
                    $range.val($targetInput.val())
                        .triggerHandler('change');
                });
            }

            function rangeHandler(event) {
                if (event.target.max !== '' && event.target.max !== undefined) {
                    var max = parseFloat(event.target.max), // get numeric value of `max` attribute
                    min = parseFloat(event.target.min),
                        newValue = Number(((event.target.value - min) * 100) / (max - min)) /*Math.round((event.target.value / (max / 100)))*/;
                        console.log(newValue)
                    $range.parent().find('style').text(selector + css.replace('{value}', newValue)); // update style while changing input value
                } else {
                    console.error('Attribute max is missing in range input!');
                }

                // update value in connected input
                if ($targetInput.length) {
                    var valueEl = event.target.value * 1,
                    valueElNumb = Number(valueEl.toFixed(0)).toLocaleString('ru-RU').split(/\s/).join(' ');                        
                    $targetInput.val(valueElNumb).triggerHandler('change');
                }
                
            }

            $range.on('input', rangeHandler)
                .on('change', rangeHandler)
                .triggerHandler('change');
        }
    });
});