jQuery.fn.tmClickAnchorWithFocus = function () {
    return jQuery(this).find('.js-scroll-with-focus').each(function () {
        var link = jQuery(this),
        target = $(link.attr('href'));

        if (link.data("init-click-anchor-with-focus") !== "init") {
            if (target !== undefined && target.length) {
                link.click(function (e) {
                    e.preventDefault();
    
                    if(window.matchMedia("(max-width: 992px)").matches) {
                        $("html").removeClass("anchor-nav-active");
    
                        $('html,body').stop().animate({
                            scrollTop: target.offset().top - 60
                        }, 'fast', function () {
                            $(target).addClass("anim-pulse-v1").delay(1000).queue(function () {
                                $(this).removeClass("anim-pulse-v1").dequeue();
                            });
                        });
                    }
    
                    else {
                        $('html,body').stop().animate({
                            scrollTop: target.offset().top
                        }, 'fast', function () {
                            $(target).addClass("anim-pulse-v1").delay(1000).queue(function () {
                                $(this).removeClass("anim-pulse-v1").dequeue();
                            });
                        });
                    }
                });
            }
        }
    })
}

$(function () {
    jQuery('body').tmClickAnchorWithFocus(['init-click-anchor-with-focus']);
});