jQuery.fn.tmCheckboxCollapse = function () {
    return jQuery(this).find('.js-collapse-checkbox').on("click",function () {
        var element = jQuery(this);
        var target = element.attr("data-collapse-target");

        $(target).collapse('toggle')
        
    })
}
$(function () {
    jQuery('body').tmCheckboxCollapse(['init-checkbox-collapse']);
});