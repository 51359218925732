jQuery.fn.tmGetFocusSelectAll = function () {
    return jQuery(this).find('.js-focus-select-all').each(function () {
        var focusedElement = jQuery(this);

        if (focusedElement.data("init-get-focus-select-all") !== "init") {
            $(document).on('focus', focusedElement, function () {
                var focusedElement = $(this);

                if (focusedElement == this) return;
                setTimeout(function () {
                    focusedElement.select();
                }, 100);
            });
        }
    })
}

$(window).on("load", function () {
    jQuery('body').tmGetFocusSelectAll(['init-get-focus-select-all']);
});