jQuery.fn.tmTabsXsV1 = function () {
    return jQuery(this).find('.js-tab-xs-v1').each(function () {
        var element = jQuery(this);

        if (element.data("init-tabs-xs-v1") !== "init") {
            if (window.matchMedia("(max-width: 767.97px)").matches) {
                element.find(".tab-pane").removeClass("active");
                element.find(".tab-list-item").removeClass("active");

                element.find(".js-tab-link-v1").on("click", function (e) {
                    $("html").addClass("ha-is-active");
                    setTimeout(function () {
                        element.find(".tab-pane.active").find(".js-video-container").addClass("is-playing");
                        element.find(".tab-pane.active").find(".js-video").get(0).play();
                    }, 500)
                });


                $(document).on("click", ".js-highlight-close-btn, .js-highlight-overlay", function (e) {
                    e.preventDefault();
                    $("html").removeClass("ha-is-active");
                    element.find(".tab-pane").removeClass("active");
                    element.find(".tab-list-item").removeClass("active");

                    var videoList = $(".js-video");
                    for (var i = 0; i < videoList.length; i++) {
                        videoList[i].pause();
                    }
                });
            }
        }
    })
}

jQuery.fn.tmTabsTopLevel = function () {
    return jQuery(this).find('.js-tab-top-level').each(function () {
        var element = jQuery(this);

        if (element.data("init-tab-top-level") !== "init") {
            var tabLink = element.find(".js-tab-link-tl");

            tabLink.on("click", function () {
                var item = $(this);

                tabLink.parent().removeClass("active")

                setTimeout(function () {
                    item.parent().addClass("active")
                }, 200)
            })
        }
    })
}

jQuery.fn.tmTabScroller = function () {
    return jQuery(this).find('.js-tab-scroller').each(function () {
        var tabSlider = jQuery(this);

        if (window.matchMedia("(min-width: 1200px)").matches && tabSlider.find(".tab-list-item").length > 6 || window.matchMedia("(max-width: 767.98px)").matches && tabSlider.find(".tab-list-item").length > 2 || window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(max-width: 1199.98px)").matches && tabSlider.find(".tab-list-item").length > 4) {
            tabSlider.slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                variableWidth: true,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
                ],
            });
        }

        if (tabSlider.hasClass("slick-slider")) {
            jQuery(this).find(".slick-slide").on("click", function () {
                var item = $(this);
                var slick = tabSlider.slick('getSlick');
                var index = item.data('slick-index');

                tabSlider.find(".tab-list-item").removeClass("active")

                setTimeout(function () {
                    item.addClass("active")
                }, 500)

                slick.slickGoTo(index);
            })
        }
    })
}

$(function () {
    function TabsUrlOpen() {
        var params = (new URL(document.location)).searchParams;
        var tab = params.get("tab");
        var tabSub = params.get("tabSub");
        var tabLink = null;
        var tabLinkSub = null;

        console.log("TabsUrlOpen called with tab:", tab, "tabSub:", tabSub);

        if (tab || tabSub) {
            tabLink = $(".js-tab-link-tl[href='#" + tab + "']");

            if (tab !== null) {
                $(".js-tab-link-tl").parent().removeClass("active");

                setTimeout(function () {
                    tabLink.parent().addClass("active");
                }, 200);
            }

            jQuery(tabLink).trigger("click");

            tabLinkSub = $('.tab-list-link[href="#' + tabSub + '"]');
            jQuery(tabLinkSub).trigger("click");

            console.log("tabLink:", tabLink);
            console.log("tabLinkSub:", tabLinkSub);

            if (tabLink.length > 0) {
                var tabList = tabLink.closest(".js-tab-list");

                setTimeout(function () {
                    jQuery('html,body').animate({
                        scrollTop: tabList.offset().top
                    }, 500);
                }, 500);
            } else if (tabLinkSub.length > 0) {
                var tabListSub = tabLinkSub.closest(".js-tabs");

                setTimeout(function () {
                    jQuery('html,body').animate({
                        scrollTop: tabListSub.offset().top
                    }, 500);
                }, 500);
            }
        }
    }

    // Trigger TabsUrlOpen on page load
    TabsUrlOpen();

    // Update the event listener to include URL parameters update
    jQuery('body').on("click", ".js-tabs-scroll", function (e) {
        e.preventDefault();

        var $link = $(this);

        var newTab = $link.data("tab");
        var newTabSub = $link.data("tabsub");

        console.log("New tab:", newTab, "New tabSub:", newTabSub);

        var newUrl = new URL(window.location.href);
        if (newTab) {
            newUrl.searchParams.set("tab", newTab);
        } else {
            newUrl.searchParams.delete("tab");
        }
        if (newTabSub) {
            newUrl.searchParams.set("tabSub", newTabSub);
        } else {
            newUrl.searchParams.delete("tabSub");
        }

        // Update the browser URL without reloading the page
        window.history.pushState({}, '', newUrl);

        // Call TabsUrlOpen to handle the new parameters
        TabsUrlOpen();
    });

    // Handle back/forward navigation
    window.addEventListener('popstate', function (event) {
        TabsUrlOpen();
    });
});

$(function () {
    jQuery('body').tmTabsXsV1(['init-tabs-xs-v1']);

    jQuery('body').tmTabsTopLevel(['init-tab-top-level']);

    jQuery('body').tmTabScroller(['init-tab-scroller']);
});