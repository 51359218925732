jQuery.fn.tmColorPicker = function () {
    return jQuery(this).find('.js-color-picker').on("click", function (e) {
        e.preventDefault();
        var picker = $(this);
        var pickerList = picker.closest(".js-color-list");

        var colorTarget = picker.attr('href');
        var hwLink = picker.attr("data-hw-link");

        $(colorTarget).closest(".js-hw-image").find("img").removeClass("active");
        pickerList.find("li").removeClass("active");
        $(colorTarget).closest(".js-hw-wrap").find(".js-hw-link").attr("href", hwLink)

        setTimeout(function () {
            picker.closest("li").addClass("active");
            $(colorTarget).addClass("active");
        }, 100)
    })
}

$(function () {
    jQuery('body').tmColorPicker(['init-color-picker']);
});