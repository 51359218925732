jQuery.fn.tmArticleImage = function () {
    return jQuery(this).find('.article-content img').each(function () {
        var element = jQuery(this);

        if (element.data("init-flip-card-function") !== "init") {
            var imgAlt = element.attr("alt")

            element.closest("p").addClass("article-image")
            element.closest("p").append('<p class="article-image-title">' + imgAlt + '</p>');
        }
    })
}
$(function () {
    jQuery('body').tmArticleImage(['init-article-image']);
});