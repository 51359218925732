jQuery.fn.tmPopOverCustom = function () {
    return jQuery(this).find('.js-popover').each(function () {
        var modalLink = jQuery(this);

        if (modalLink.data("init-popover-custom") !== "init") {
            modalLink.data("init-popover-custom", "init").on("click", function (e) {
                setTimeout(function () {
                    var dataClass = $(".js-popover-custom").attr("data-custom-class");
                    $(".js-popover-custom").closest(".popover").addClass(dataClass);
                }, 100);
            })

            setTimeout(function () {
                $(".js-popover-custom").removeClass("d-none");
            }, 200);
        }
    })
}

$(function () {
    jQuery('body').tmPopOverCustom(['init-popover-custom']);
});