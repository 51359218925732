jQuery.fn.tmtabExtraContent = function () {
    return jQuery(this).find('*[data-toggle="tab"]').each(function () {
        var element = jQuery(this);

        if (element.data("init-tab-extra-content") !== "init") {
            $(element).on("click", function (e) {
                var elementData = jQuery(element.data("text-target"));

                if (elementData.length) {
                    $(".js-tabs-extra-text").removeClass("show-up");
                    
                    setTimeout(function() {
                        elementData.addClass("show-up")
                    }, 50)
                }
            });
        }
    });
}

$(function () {
    jQuery('body').tmtabExtraContent(['init-tab-extra-content']);
});