jQuery.fn.lastSliderItemLineUp = function () {
    return jQuery(this).find(".js-slider-last-item-css").each(function () {
        var element = $(this);

        element.on("afterChange", function () {
            var slider = $(this);
            var item = slider.find(".slick-active");
            var slickSliderWidth = item.closest(".slick-slider").innerWidth();
            var slickItemWidth = slider.find(".slick-slide").innerWidth();

            if (item.is(":last-child")) {
                item.addClass("last-active-item");
                item.closest(".slick-slider").find(".slick-list").css("padding-left", (slickSliderWidth - slickItemWidth))
                //item.closest(".slick-slider").addClass("last-item-css")
            } else {
                item.removeClass("last-active-item");
                item.closest(".slick-slider").find(".slick-list").css("padding-left", "0")
                //item.closest(".slick-slider").removeClass("last-item-css")
            }
        });
    });
}

$(function () {
    $(window).on("load", function () {
        if (window.matchMedia("(max-width: 767.98px)").matches) {
            jQuery('body').lastSliderItemLineUp(['init-last-slider-item-line-up']);
        }
    })
})