jQuery.fn.tmOverlayComponentBackground = function () {
    return jQuery(this).find('.js-overlay-component').each(function () {
        var imageObject = jQuery(this);

        if (imageObject.data("init-overlay-component-background") !== "init") {
            if (window.matchMedia("(min-width: 1200px)").matches) {
                var elementXL = imageObject.data("height-xl");
                imageObject.css('height', elementXL);
            }

            if (window.matchMedia("(max-width: 1199px)").matches) {
                var elementLG = imageObject.data("height-lg");
                imageObject.css('height', elementLG);
            }

            if (window.matchMedia("(max-width: 991px)").matches) {
                var elementMD = imageObject.data("height-md");
                imageObject.css('height', elementMD);
            }

            if (window.matchMedia("(max-width: 767.97px)").matches) {
                var elementXs = imageObject.data("height-xs");
                imageObject.css('height', elementXs);
            }
        }
    })
}

$(function () {
    jQuery('body').tmOverlayComponentBackground(['init-overlay-component-background']);
});

$(window).on("resize", function () {
    jQuery('body').tmOverlayComponentBackground(['init-overlay-component-background']);
});