jQuery.fn.tmSwitchContent = function () {
    return jQuery(this).find('.js-switch-link').each(function () {
        var element = jQuery(this);

        if (element.data("init-switch-content") !== "init") {
            $(element).on("click", function (e) {
                e.preventDefault();

                element.closest(".js-switch-content").addClass("is-hidden")
            });
        }
    });
}

$(function () {
    jQuery('body').tmSwitchContent(['init-switch-content']);
});