jQuery.fn.tmTabLinkActiveScroll = function () {
    return jQuery(this).find('.js-tab-link').each(function () {
        var element = jQuery(this);

        if (element.data("init-tab-link-active-on-scroll") !== "init") {
            if (element.data("function") == "scrollTop") {
                element.on("click", function () {
                    if (window.matchMedia("(max-width: 992px)").matches) {
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $(".js-scroll-to").offset().top - 60
                            }, 500);
                        }, 200)
                    } else {
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $(".js-scroll-to").offset().top
                            }, 500);
                        }, 200)
                    }
                });
            }
        }
    })
}
$(function () {
    jQuery('body').tmTabLinkActiveScroll(['init-tab-link-active-on-scroll']);
});