$(function () {
    if ($(".js-range-calculation-v1").length > 0) {
        $(".js-range-calculation-v1").each(function () {
            var range = $(this);
            targetInput = $(range.data("target-input"));

            calcRange(range);

            range.on("input", function () {
                calcRange(range);
            });

            targetInput.on("input", function () {
                range.val(targetInput.val());
                calcRange(range);
            });
        });
    }

    function calcRange(range) {
        var rangeVal = range.val();

        /*SHOWING DISCOUNT*/
        if (rangeVal >= 5000 && rangeVal <= 7000) {
            $(".js-range-discount").html("30");
        } else if (rangeVal >= 4000 && rangeVal <= 4900) {
            $(".js-range-discount").html("25");
        } else if (rangeVal >= 3000 && rangeVal <= 3900) {
            $(".js-range-discount").html("20");
        } else if (rangeVal >= 2000 && rangeVal <= 2900) {
            $(".js-range-discount").html("15");
        } else if (rangeVal >= 1000 && rangeVal <= 1900) {
            $(".js-range-discount").html("10");
        } else if (rangeVal >= 500 && rangeVal <= 900) {
            $(".js-range-discount").html("5");
        }

        /* CALCULATING DISCOUNT */
        var dataAfterDiscount = (rangeVal / 100) * $(".js-range-discount").html(),
            dAdResult = Number(dataAfterDiscount.toFixed(0)).toLocaleString('ru-RU').split(/\s/).join(' ');
        var dataDiscountTimes24 = 24 * dataAfterDiscount,
            dDt24Result = Number(dataDiscountTimes24.toFixed(0)).toLocaleString('ru-RU').split(/\s/).join(' ');

            
        /* CALCULATING DISCOUNT FOR SMARTPHONE */
        var smartPhoneDiscountDeficit = ((rangeVal / 10) + 10) * 1.21;
        var smartPhoneDiscount = Math.ceil(smartPhoneDiscountDeficit / 10) * 10,
            sPDResult = Number(smartPhoneDiscount.toFixed(0)).toLocaleString('ru-RU').split(/\s/).join(' ');
        var smartPhoneDiscountTimes24 = rangeVal * 4 /*24 * smartPhoneDiscount*/;
        var sPdT24Result = 0;

        if( rangeVal >= 501) {
            sPdT24Result = Number(smartPhoneDiscountTimes24.toFixed(0)).toLocaleString('ru-RU').split(/\s/).join(' ');
        }

        else {
            sPdT24Result = 0
        }

        $(".js-data-after-discount").html(dAdResult)
        $(".js-data-discount-times-24").html(dDt24Result)
        $(".js-data-smartphone-discount").html(sPDResult)
        $(".js-data-smartphone-times-24").html(sPdT24Result);
    }
});