$(function () {
    jQuery.fn.tmLazyLoad = function () {
        return jQuery(this).find('[data-lazy], [data-lazy-xs], [data-lazy-sm], [data-lazy-md], [data-lazy-lg], [data-lazy-xl]').each(function () {
            var $wrapper = $(this);
            var imageUrl = getLazyImageUrl($wrapper); // Function to determine the correct image URL

            if (imageUrl) {
                var imgElement = $('<img>').on('load', function () {
                    if ($wrapper.hasClass('js-lazy-img')) {
                        $wrapper.attr('src', imageUrl);
                    } else {
                        $wrapper.css('background-image', 'url(' + imageUrl + ')');
                    }
                });

                imgElement.attr('src', imageUrl); // Trigger image loading
            }
        });
    };

    function getLazyImageUrl($wrapper) {
        var windowWidth = $(window).width();
        var imageUrl;

        // Define your breakpoint values
        var breakpoints = {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        };

        if (windowWidth >= breakpoints.xl && $wrapper.data('lazy-xl')) {
            imageUrl = $wrapper.data('lazy-xl');
        } else if (windowWidth >= breakpoints.lg && $wrapper.data('lazy-lg')) {
            imageUrl = $wrapper.data('lazy-lg');
        } else if (windowWidth >= breakpoints.md && $wrapper.data('lazy-md')) {
            imageUrl = $wrapper.data('lazy-md');
        } else if (windowWidth >= breakpoints.sm && $wrapper.data('lazy-sm')) {
            imageUrl = $wrapper.data('lazy-sm');
        } else if ($wrapper.data('lazy-xs')) {
            imageUrl = $wrapper.data('lazy-xs');
        } else {
            // Default to the standard data-lazy if no breakpoint-specific URL is provided
            imageUrl = $wrapper.data('lazy');
        }

        return imageUrl;
    }

    // Create a new Intersection Observer
    var observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            
            if (entry.isIntersecting) {
                jQuery("body").tmLazyLoad();
                observer.unobserve(entry.target);
            }
        });
    }, {
        rootMargin: '200px',
        threshold: 0
    });

    // Get all elements with the data-lazy attribute and observe each one
    $('[data-lazy], [data-lazy-xs], [data-lazy-md], [data-lazy-lg], [data-lazy-xl]').each(function () {
        // Start observing the element
        observer.observe(this);
    });

    $(".lightbox").on("click", function() {
        jQuery("body").tmLazyLoad();
    })
});


