jQuery.fn.tmCloudCalculator = function () {
    return jQuery(this).find('.js-cloud-calc-input').each(function () {
        var element = jQuery(this);

        if (element.data("init-cloud-calc-input") !== "init") {
            var amountTable = jQuery(element.data("target-amount-table"));
            var priceTable = jQuery(element.data("target-price"));
            var productPrice = element.data("product-price");

            // connect with target input
            if (amountTable.length) {
                element.on('input', function () {
                    var val = element.val();
                    amountTable.text(val).triggerHandler('change');
                });
            }

            if (priceTable.length) {
                element.on('input', function () {
                    var val = element.val()
                    var calculation = val * productPrice;
                    priceTable.text(calculation.toLocaleString("cs-CZ")).triggerHandler('change');
                    priceTable.attr('data-product-price', calculation).triggerHandler('change');
                });
            }

            function rangeHandler(event) {
                var sum = 0
                if (amountTable.length) {
                    var val = element.val()
                    amountTable.text(val).triggerHandler('change');
                }

                if (priceTable.length) {
                    var val = element.val()
                    var calculation = (val * productPrice);
                    priceTable.text(calculation.toLocaleString('cs-CZ')).triggerHandler('change');
                    priceTable.data('product-price', calculation).triggerHandler('change');
                }

                $(".js-cloud-price-monthly").each(function() {
                    var cloudPriceMonthly = $(this);
                    var cloudPriceElement = cloudPriceMonthly.prev(".js-cloud-price");
                    var cloudPrice = cloudPriceElement.data("product-price");

                    var monthlyCalc = cloudPrice * (730);

                    cloudPriceMonthly.text(monthlyCalc.toLocaleString('cs-CZ'))
                })

                $(".js-cloud-price").each(function () {
                    var cloudPrice = $(this);
                    var CloudPriceNumber = cloudPrice.data("product-price");
                    sum += Number(CloudPriceNumber);
                });

                $(".js-hour-price").text(sum.toLocaleString('cs-CZ'));

                var monthlyCalc = sum * (730);
                $(".js-monthly-price").text(monthlyCalc.toLocaleString("cs-CZ"))

            }

            element.on('input', rangeHandler)
                .on('change', rangeHandler)
                .triggerHandler('change');
        }
    })
}

$(window).on("load", function () {
    jQuery('body').tmCloudCalculator(['init-cloud-calc-input']);
});