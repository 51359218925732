jQuery.fn.tmVideoLazyLoad = function () {
    return jQuery(this).find('.js-video-load').each(function () {
        var videoItem = jQuery(this);

        if (videoItem.data("init-video-lazy-load") !== "init") {
            var $window = $(window);
            var itemHeight = videoItem.outerHeight();
            var itemOffset = videoItem.offset();
            var scrollGap = 0;
            var source = "";

            if ($window.scrollTop() > itemOffset.top && $window.scrollTop() <= itemOffset.top + itemHeight + scrollGap && !videoItem.hasClass("is-visible") || videoItem.hasClass("on-load-video") && !videoItem.hasClass("is-visible")) {
                if (window.matchMedia("(max-width: 767.97px)").matches) {
                    source = videoItem.attr("data-src-xs");
                }

                else {
                    source = videoItem.attr("data-src-md");
                }

                 videoItem.attr("src", source).addClass("is-visible");

                 setTimeout(function() {
                    videoItem.get(0)
                    if (videoItem.get(0).paused) {
                        videoItem.get(0).controls = false;
                        videoItem.get(0).play();
                    }
                 }, 400)

            } else {
                
            }
        }
    })
}

$(function () {
    $(window).on("scroll", function () {
        jQuery('body').tmVideoLazyLoad(['init-video-lazy-load']);
    });

    $(window).on("load", function () {
        jQuery('body').tmVideoLazyLoad(['init-video-lazy-load']);
    });
});
