jQuery.fn.tmFlipCardFunction = function () {
    return jQuery(this).find('.js-flip-card-link').on("click", function (e) {
        e.preventDefault();
        var element = jQuery(this);
        var elementContainer = element.closest(".js-flip-card-container");
        var elementCard = elementContainer.find(".js-flip-card");
        var elementHeight = elementContainer.find(".js-flip-card-inner").innerHeight();

        elementContainer.toggleClass("active-card");

        if((elementHeight + 15) > elementContainer.innerHeight()) {
            elementCard.addClass("scroll-active")
        }
    })
}

$(function () {
    jQuery('body').tmFlipCardFunction(['init-flip-card-function']);
});