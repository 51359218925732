jQuery.fn.tmActiveLinkOnScroll = function () {
    return jQuery(this).find('.js-scroll-nav-link').each(function () {
        var element = jQuery(this);
        var currentPosition = $(window).scrollTop();

        if (element.data("init-active-link-on-scroll") !== "init") {
            if (element.closest(".js-navbar-item").hasClass("active")) {
                var tar = $("#" + $(this).attr("href").replace("#", ""));
                var top = tar.closest("div").offset().top,
                    bottom = top + tar.closest("div").height();
                if (currentPosition >= top && currentPosition <= bottom) {
                    $(".js-scroll-nav-item").removeClass("active");
                    $(this).parent().addClass("active");
                } else if (currentPosition == $(document).height() - $(window).height()) {
                    $(".js-scroll-nav-item").removeClass("active");
                    $(".js-scroll-nav-item:last-child").addClass("active");
                } else {
                    $(this).parent().removeClass("active");
                }
            }
        }
    })
}
$(function () {
    $(document).on("scroll", function(){
        jQuery('body').tmActiveLinkOnScroll(['init-active-link-on-scroll']);
    });
});