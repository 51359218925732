jQuery.fn.tmSectionClass = function () {
    return jQuery(this).find('.js-section-class').each(function () {
        var element = jQuery(this);

        if (element.data("init-section-class") !== "init") {
           var classToAdd = element.data("section-class");

           element.closest(".bs-section").addClass(classToAdd)
        }
    })
}

$(function () {
    jQuery('body').tmSectionClass(['init-section-class']);
});