jQuery.fn.tmInputShowPassword = function () {
    return jQuery(this).find('.js-input-show-password').on("click", function (e) {
        e.preventDefault();
        var inputPassBtn = jQuery(this);
        var inputPassInput = inputPassBtn.closest(".js-input-password-wrap").find(".js-input-password")

        inputPassBtn.toggleClass("is-active");

        console.log(inputPassInput.type)

        if (inputPassInput.attr('type') === "password") {
            
            inputPassInput.attr('type', "text");
        } else {
            inputPassInput.attr('type', "password")
        }
    })
}

$(window).on("load", function () {
    jQuery('body').tmInputShowPassword(['init-input-show-password']);
});