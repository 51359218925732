jQuery.fn.tmCheckoutCollapse = function () {
    return jQuery(this).find('.js-checkout-collapse').each(function () {
        var link = jQuery(this);

        if (link.data("init-checkout-collapse") !== "init") {
            $(".js-checkout-collapse").on("click", function () {
                var element = $(this); 
                var eleWrap = element.closest(".js-checkout-basket");

                if(eleWrap.hasClass("is-open")) {
                    setTimeout(function() {
                        eleWrap.removeClass("is-open");
                    }, 100)
                }

                else {
                    setTimeout(function() {
                        eleWrap.addClass("is-open");
                    }, 100)
                }
            });

            if (window.matchMedia("(max-width: 767.98px)").matches) {
                $(".js-checkout-content").addClass("collapse")
            }
        }
    })
}

$(function () {
    jQuery('body').tmCheckoutCollapse(['init-checkout-collapse']);
});