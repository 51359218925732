jQuery.fn.tmVideoPlayButton = function () {
    return jQuery(this).find('.js-video-btn').each(function () {
        var videoButton = jQuery(this);

        if (videoButton.data("init-video-play-button") !== "init") {
            videoButton.on("click", function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();

                videoButton.closest(".js-video-container").addClass("is-playing");

                if (videoButton.prev(".js-video").hasClass("iframe-video")) {
                    setTimeout(function () {
                        if (videoButton.closest(".js-video-container").hasClass("is-playing")) {
                            videoButton.prev(".js-video").get(0).src += "?autoplay=1";
                        }
                    }, 500)
                } else {
                    videoButton.prev(".js-video").get(0).play();
                }

                return;
            });

            $(".js-tab-link-v1").each(function () {
                var element = $(this);

                element.on("click", function () {
                    var videoList = $(".js-video");
                    for (var i = 0; i < videoList.length; i++) {
                        videoList[i].pause();
                    }
                });
            });
        }
    })
}

$(function () {
    jQuery('body').tmVideoPlayButton(['init-video-play-button']);
});
