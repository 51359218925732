jQuery.fn.tmMovieSlideshow = function () {
    return jQuery(this).find('.js-movie-slideshow').each(function () {
        var element = jQuery(this);
        var elementSpeed = element.data("slick-speed");

        if (element.data("init-movie-slideshow") !== "init") {
            var speed = 0;

            if (elementSpeed !== "") {
                speed = elementSpeed
            } else {
                speed = 3000
            }

            element.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                accessibility: false,
                arrows: false,
                infinite: true,
                variableWidth: true,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                speed: speed,
            });

            if (element.find(".slideshow-list-item").length > 4) {
                element.addClass("slick-dots-scroller")
            }
        }

        var observer = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              lazyLoad(entry.target)
              observer.unobserve(entry.target)
            }
          })
        }, {
          rootMargin: '500px'
        })

        function lazyLoad (element) {
          $(element).find('[data-lazy]').each(function () {
            var $wrapper = $(this)
            var imageUrl = $(this).data('lazy')
            $wrapper.removeAttr('data-lazy')
      
            if (imageUrl) {
              var imgElement = $('<img />').attr('src', imageUrl).on('load', function() {
                  if($wrapper.hasClass('js-lazy-img')) {
                    $wrapper.attr('src', imageUrl)
                  }

                  else {
                    $wrapper.css('background-image', 'url(' + imageUrl + ')')
                  }
                })
            }
          })
        }

        observer.observe(element[0])
    })
}

jQuery.fn.tmMovieHighLightShow = function () {
    return jQuery(this).find('.js-movie-highlight-swipe').each(function () {
        var element = jQuery(this);

        if (element.data("init-movie-highlight-show") !== "init") {
            function smartArrowPositions() {
                if (window.matchMedia("(min-width: 768px) and (max-width: 2500px)").matches) {
                    var offset = $(".slick-center").offset();
                    var offsetLeft = offset.left;
                    var offsetRight = $(window).width() - offsetLeft - $(".slick-center").outerWidth(true);

                    console.log(offsetLeft)

                    element.find(".slick-prev").css("left", offsetLeft - 30);
                    element.find(".slick-next").css("right", offsetRight - 30);
                }
            }

            element.on('init', function (event, slick) {
                smartArrowPositions();
            });

            $(window).on("resize", function () {
                setTimeout(function () {
                    smartArrowPositions();
                }, 500)
            });

            if (window.matchMedia("(min-width: 768px)").matches) {
                element.slick({
                    slidesToShow: 1,
                    accessibility: false,
                    centerMode: true,
                    variableWidth: true,
                    arrows: true,
                    centerMode: true,
                    infinite: true,
                });
            } else {
                element.slick({
                    accessibility: false,
                    mobileFirst: true,
                    arrows: false,
                    dots: true,
                    autoplay: true,
                    slidesToShow: 1,
                    autoplaySpeed: 5000,
                    pauseOnHover: true,
                    pauseOnFocus: true,
                });

                $(".js-trailer-trigger").each(function () {
                    var element = $(this);

                    element.on('touchstart', function (e) {
                        element.closest(".js-movie-highlight-swipe").slick('slickPause');
                    });
                });

                element.on('touchstart', function (e) {
                    element.slick('slickPlay');
                });
            }


            var observer = new IntersectionObserver(function(entries, observer) {
              entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                  lazyLoad(entry.target)
                  observer.unobserve(entry.target)
                }
              })
            }, {
              rootMargin: '500px'
            })
    
            function lazyLoad (element) {
              $(element).find('[data-lazy]').each(function () {
                var $wrapper = $(this)
                var imageUrl = $(this).data('lazy')
                $wrapper.removeAttr('data-lazy')
          
                if (imageUrl) {
                  var imgElement = $('<img />')
                    .attr('src', imageUrl)
                    .on('load', function() {
                      $wrapper.css('background-image', 'url(' + imageUrl + ')')
                    })
                }
              })
            }
    
            observer.observe(element[0])
        }
    })
}

$(function () {
    jQuery('body').tmMovieHighLightShow(['init-movie-highlight-show']);

    jQuery('body').tmMovieSlideshow(['init-movie-slideshow']);
});
