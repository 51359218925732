jQuery.fn.tmSmartGuideComponent = function () {
    return jQuery(this).find('.js-smart-guide-c').each(function () {
        var item = jQuery(this);

        if (item.data("init-smart-guide-component") !== "init") {
            item.find(".js-sgc-link").on("click", function () {
                var element = $(this);
                var elementImage = element.attr("data-sgc-image");
                var closestWrap = element.closest(".js-smart-guide-c");

                closestWrap.find(".js-sgc-link").removeClass("is-active");
                closestWrap.find(".js-sgc-image-wrap li").removeClass("is-active")

                setTimeout(function () {
                    element.addClass("is-active")
                    $(elementImage).addClass("is-active")
                }, 150)
            });

            item.find(".js-sgc-prev").on("click", function () {
                var clickElement = $(this);
                var sgcLink = clickElement.closest(".js-smart-guide-c").find(".js-sgc-link");

                sgcLink.each(function () {
                    var item = $(this);

                    if (item.hasClass("is-active")) {
                        var activeItem = $(this);

                        if (activeItem.hasClass("js-first-item")) {
                            var firstItem = $(this);
    
                            firstItem.closest(".js-sgc-link-list").find(".js-last-item").trigger( "click" )
                        }

                        else {
                            activeItem.prev().trigger( "click" )
                        }
                    }
                })
            })

            item.find(".js-sgc-next").on("click", function () {
                var clickElement = $(this);
                var sgcLink = clickElement.closest(".js-smart-guide-c").find(".js-sgc-link");

                sgcLink.each(function () {
                    var item = $(this);

                    if (item.hasClass("is-active")) {
                        var activeItem = $(this);

                        if (activeItem.hasClass("js-last-item")) {
                            var lastItem = $(this);
    
                            lastItem.closest(".js-sgc-link-list").find(".js-first-item").trigger( "click" )
                        }

                        else {
                            activeItem.next().trigger( "click" )
                        }
                    }
                })
            })
        }
    })
}

$(function () {
    jQuery('body').tmSmartGuideComponent(['init-smart-guide-component']);
});