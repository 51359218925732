jQuery.fn.tmSlideShowV2 = function () {
    return jQuery(this).find('.js-slideshow-v2').each(function () {
        var element = jQuery(this);

        if (element.data("init-slide-show-v2") !== "init") {
            element.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                accessibility: false,
                dots: true,
                speed: 300,
                customPaging: function(element, i) { 
                    return '<button><span class="ss-dots-image" style="background-image:url('+$(element.$slides[i]).data('thumb')+');"></span></button>';
                },
            });

            if (element.find(".slideshow-list-item").length > 4) {
                element.addClass("slick-dots-scroller")
            }
        }
    })
}
$(function () {
    jQuery('body').tmSlideShowV2(['init-slide-show-v2']);
});