jQuery.fn.tmTextReaderComponent = function () {
    return jQuery(this).find('.js-text-reader').each(function () {
        var element = jQuery(this);
        var startPoint = element.closest(".js-reader-start-point");

        if (element.data("init-text-reader-component") !== "init") {
            if (startPoint.hasClass("js-reader-is-visible") && element.hasClass("ready-to-play")) {
                element.children(".js-text-reader-item").each(function () {
                    var item = $(this);
                    var elementTimer = item.find(".text-reader-timer");

                    if (item.hasClass("is-reading")) {
                        var element = $(this)
                        var itemReadTime = element.attr("data-read-time");
                        var readTimeMS = itemReadTime * 1000;
                        var elementTimer = element.find(".text-reader-timer");
                        var itemHeight = element.outerHeight();
                        var textImage = element.attr("data-text-reader-image");

                        element.removeClass("in-queue")
                        element.next().addClass("in-queue");
                        $(textImage).addClass("is-active")

                        if (elementTimer.length <= 0) {
                            $("<div class='text-reader-timer'></div>").prependTo(element);
                        }

                        elementTimer.animate({
                            height: itemHeight + "px",
                        }, readTimeMS, function () {
                            elementTimer.animate({
                                height: 0,
                            }, 1)
                        });

                        if (!element.closest(".js-text-reader").hasClass("is-clicked")) {
                            if (element.next().length <= 0) {
                                element.closest(".js-text-reader").find(".text-reader-item:first-of-type").addClass("loop-reset");
                            }
                        }

                        if (element.next().length <= 0) {
                            element.closest(".js-text-reader").find(".text-reader-item:first-of-type").addClass("in-queue");
                        }

                        element.delay(readTimeMS).queue(function () {
                            $(".ready-to-play .js-text-reader-item").removeClass("is-reading");
                            $(".js-text-reader-image").removeClass("is-active");
                            $(".text-reader-timer").stop();
                            $(".text-reader-timer").animate({
                                height: 0,
                            }, 1);

                            $(".js-text-reader-observer").toggleClass("anim-step");

                            if (element.hasClass("loop-reset")) {
                                $(".js-text-reader-item").dequeue();
                            }

                            setTimeout(function () {
                                $(".in-queue").addClass("is-reading");
                            }, 100);
                        });
                    }
                });
            }
        }
    })
}

$(function () {
    // select all elements with the class "my-class"
    var elements = document.querySelectorAll('.js-observer');

    // create an observer instance
    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            // check if the classList has been modified
            if (mutation.attributeName === 'class') {
                // loop through all elements
                elements.forEach(function (element) {
                    // console.log("it workds")

                    setTimeout(function () {
                        jQuery('body').tmTextReaderComponent(['init-text-reader-component'])
                    }, 100)
                });
            }
        });
    });

    // start observing the elements
    elements.forEach(function (element) {
        observer.observe(element, {
            attributes: true
        });
    });

    function smartReader() {
        var $window = $(window);

        $(".js-reader-start-point").each(function () {
            var item = $(this);
            var itemHeight = item.outerHeight();
            var itemOffset = item.offset();
            var scrollGap = 20;

            if ($window.scrollTop() > itemOffset.top && $window.scrollTop() <= itemOffset.top + itemHeight + scrollGap) {
                item.addClass("js-reader-is-visible");
            } else {
                item.removeClass("js-reader-is-visible");
            }
        })

    }

    $(window).on("scroll", function () {
        smartReader();
    });

    $(window).on("load", function () {
        smartReader();

        setTimeout(function () {
            $(".js-text-reader-item").clearQueue();
            jQuery('body').tmTextReaderComponent(['init-text-reader-component']);
        }, 200)

        $(".js-text-reader-item").on("click", function () {
            var item = $(this);
            item.closest(".js-text-reader").addClass("is-clicked")
            $(".js-text-reader-item").dequeue().removeClass("loop-reset");
            $(".js-text-reader-item").clearQueue()
            $(".text-reader-timer").stop().animate({
                height: 0,
            }, 1)

            setTimeout(function () {
                $(".ready-to-play .js-text-reader-item").removeClass("is-reading in-queue")
            }, 100)

            setTimeout(function () {
                item.removeClass("in-queue")
                item.addClass("is-reading loop-reset");
            }, 120)

            setTimeout(function () {
                jQuery('body').tmTextReaderComponent(['init-text-reader-component']);
            }, 130)
        });

        $(".js-text-reader-toggle").on("click", function () {
            var item = $(this);
            $(".js-text-reader").removeClass("ready-to-play");
            $(".js-text-reader-observer").toggleClass("switch-tabs");
            $(".js-text-reader-item").clearQueue();

            $(".js-text-reader-item").each(function () {
                var element = $(this);

                if (element.hasClass("is-reading") && element.hasClass("in-queue")) {
                    element.removeClass("is-reading")
                }
            })


            setTimeout(function () {
                var href = item.attr('href');
                $(href).find(".js-text-reader").addClass("ready-to-play");
            }, 100)
        })
    });
});