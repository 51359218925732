jQuery.fn.tmFilterSliderXS = function () {
    return jQuery(this).find('.js-filter-slider-xs').each(function () {
        var element = jQuery(this);

        if (element.data("init-filter-slider-xs") !== "init") {
            if (window.matchMedia("(max-width: 767.98px)").matches) {
                element.slick({
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: false,
                    accessibility: false,
                    variableWidth: true,
                    infinite: false,
                    speed: 300,
                });
            }
        }
    })
}

jQuery.fn.tmSliderV5 = function () {
    return jQuery(this).find('.js-slider-v5').each(function () {
        var slider = $(this);

        if (window.matchMedia("(min-width: 1200px)").matches && slider.find(".tarif-slider-item").length > 4 || window.matchMedia("(max-width: 1199.98px)").matches && slider.find(".tarif-slider-item").length > 2) {
            slider.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                accessibility: false,
                infinite: false,
                speed: 300,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots: true
                    }
                },
                ],
            });
        }
    });
}

jQuery.fn.tmSliderV3 = function () {
    return jQuery(this).find('.js-slider-v3').each(function () {
        var sliderV3 = jQuery(this);

        if (sliderV3.data("init-slider-v3") !== "init") {
            sliderV3.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                prevArrow: '<div class="slider-v3-navigation slick-prev"><button type="button" class="sl-inner-arrow sl-prev">Previous</button></div>',
                nextArrow: '<div class="slider-v3-navigation slick-next"><button type="button" class="sl-inner-arrow sl-next">Next</button></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                    }
                }]
            });

            setTimeout(function () {
                sliderV3.find(".slick-track").addClass("d-flex");
            }, 100);
        }
    })
}

jQuery.fn.tmSliderV4 = function () {
    return jQuery(this).find('.js-slider-v4').each(function () {
        var sliderV4 = jQuery(this);

        if (sliderV4.data("init-slider-v4") !== "init") {
            sliderV4.slick({
                slidesToShow: 3,
                variableWidth: true,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                prevArrow: '<div class="slider-navigation slick-prev"><button type="button" class="sl-inner-arrow sl-prev">Previous</button></div>',
                nextArrow: '<div class="slider-navigation slick-next"><button type="button" class="sl-inner-arrow sl-next">Next</button></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots: true
                    }
                }]
            });
        }
    })
}

jQuery.fn.tmSliderMD = function () {
    return jQuery(this).find('.js-slider-md').each(function () {
        var sliderV4 = jQuery(this);

        if (sliderV4.data("init-slider-md") !== "init") {
            sliderV4.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                speed: 300,
                prevArrow: '<div class="slider-navigation slick-prev"><button type="button" class="sl-inner-arrow sl-prev">Previous</button></div>',
                nextArrow: '<div class="slider-navigation slick-next"><button type="button" class="sl-inner-arrow sl-next">Next</button></div>',
            });
        }
    })
}

jQuery.fn.tmSliderTile = function () {
    return jQuery(this).find('.js-slider-tile').each(function () {
        var sliderTile = jQuery(this);

        if (sliderTile.data("init-slider-tile") !== "init") {
            sliderTile.slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                variableWidth: true,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                        arrows: false
                    }
                }]
            });
        }
    })
}

jQuery.fn.tmSliderUniversal = function () {
    return jQuery(this).find('.js-slider-universal').each(function () {
        var slider = jQuery(this);

        if (slider.data("init-slider-universal") !== "init") {
            var sliderVisible = slider.data("items-visible")
            var sliderXSVisible = slider.data("items-visible-xs")
            var sliderMDToScroll = 1
            var sliderMDVisible = null
            var slideCenteredModeXs = false;
            var sliderDots = false;
            var hideSlickxs = false

            if (sliderMDVisible === '') {
                sliderMDVisible = sliderVisible
            } else {
                sliderMDVisible = slider.data("items-visible-md")
            }

            if (slider.data("slick-centered-xs")) {
                slideCenteredModeXs = true
            }

            if (slider.data("slick-dots")) {
                sliderDots = true
            }

            if (slider.data("items-scroll")) {
                sliderMDToScroll = slider.data("items-scroll")
            }

            if (slider.data("slick-hide-xs")) {
                hideSlickxs = true
            }

            slider.slick({
                slidesToShow: sliderVisible,
                slidesToScroll: sliderMDToScroll,
                dots: sliderDots,
                variableWidth: true,
                accessibility: false,
                infinite: false,
                speed: 300,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: sliderMDVisible,
                        dots: true,
                        touchThreshold: 500
                    }
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: sliderXSVisible,
                        dots: true,
                        touchThreshold: 500,
                        centerMode: slideCenteredModeXs,
                        centerPadding: '0',
                    }
                }]
            });

            if (window.matchMedia("(max-width: 767.98px)").matches && hideSlickxs === true) {
                slider.slick('unslick');
            }
        }
    })
}

jQuery.fn.tmTileSliderMD = function () {
    return jQuery(this).find('.js-tile-slider-md-v1').each(function () {
        var slider = jQuery(this);

        if (slider.data("init-tile-slider-md-v1") !== "init") {
            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                accessibility: false,
                infinite: false,
                speed: 300,
                dots: true,
            });
        }
    })
}

jQuery.fn.tmTileSliderMDV2 = function () {
    return jQuery(this).find('.js-tile-slider-md-v2').each(function () {
        var slider = jQuery(this);

        if (slider.data("init-tile-slider-v2") !== "init") {
            var sliderVisible = slider.data("items-visible")
            var sliderXSVisible = slider.data("items-visible-xs")
            slider.slick({
                slidesToShow: sliderVisible,
                slidesToScroll: 1,
                variableWidth: true,
                accessibility: false,
                infinite: false,
                speed: 300,
                dots: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: sliderXSVisible,
                    }
                }]
            });
        }
    })
}

$(function () {
    $(window).on("load", function () {
        setTimeout(function () {
            jQuery('body').tmFilterSliderXS(['init-filter-slider-xs']);
            jQuery('body').tmSliderV3(['init-slider-v3']);
            jQuery('body').tmSliderV4(['init-slider-v4']);
            jQuery('body').tmSliderV5(['init-slider-v5']);
            jQuery('body').tmSliderTile(['init-slider-tile']);
            jQuery('body').tmSliderUniversal(['init-slider-universal']);

            jQuery('body').tmSliderV5(['init-slider-v5']);

            $(".js-slideshow").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                infinite: true
            });

            $(".js-slider-2").slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                prevArrow: '<div class="slider-navigation slick-prev"><button type="button" class="sl-inner-arrow sl-prev">Previous</button></div>',
                nextArrow: '<div class="slider-navigation slick-next"><button type="button" class="sl-inner-arrow sl-next">Next</button></div>',
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                        arrows: false
                    }
                }]
            });

            $(".js-slider-3").slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                variableWidth: true,
                accessibility: false,
                infinite: false,
                speed: 300,
                prevArrow: '<div class="slider-navigation slick-prev"><button type="button" class="sl-inner-arrow sl-prev">Previous</button></div>',
                nextArrow: '<div class="slider-navigation slick-next"><button type="button" class="sl-inner-arrow sl-next">Next</button></div>',
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        dots: true
                    }
                }]
            });

            $('.js-grid-slider-v1').slick({
                infinite: true,
                centerPadding: '0',
                slidesToShow: 1,
                variableWidth: true,
                prevArrow: '<div class="slick-arrow-nav d-none d-md-block"><button type="button" class="sl-arrow slick-prev">Previous</button></div>',
                nextArrow: '<div class="slick-arrow-nav d-none d-md-block"><button type="button" class="sl-arrow slick-next">Next</button></div>',
                responsive: [{
                    breakpoint: 767,
                    settings: "unslick"
                }]
            });

            $(".js-logo-slider").slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                dots: false,
                accessibility: false,
                infinite: false,
                speed: 300,
                arrows: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        variableWidth: true,
                        slidesToShow: 2,
                        arrows: false
                    }
                }]
            });
        }, 1000);
    });

    if (window.matchMedia("(min-width: 768px)").matches) {
        jQuery('body').tmSliderMD(['init-slider-md']);

        $(document).on("click", ".lightbox", function () {
            setTimeout(function () {
                if ($(".bootbox [class*='js-slider']").length > 0) {
                    jQuery('body').tmSliderMD(['init-slider-md']);
                }
            }, 400)
        });
    }

    if (window.matchMedia("(max-width: 580px)").matches) {
        $(".js-tab-slider-xs-v1").each(function () {
            var slider = $(this);
            var sliderItem = slider.find(".tab-list-item")
            var jsTabTtemLength = sliderItem.length;

            sliderItem.each(function () {
                var element = $(this);

                if (element.is(":last-child")) {
                    element.addClass("slick-last-item")
                }
            });

            if (jsTabTtemLength > 3) {
                slider.addClass("tab-slider-xs-four-items");

                sliderItem.each(function () {
                    var element = $(this);

                    if (element.is(":nth-child(3)")) {
                        element.addClass("slick-special-trigger")
                    }
                });
            } else {
                sliderItem.each(function () {
                    var element = $(this);

                    if (element.is(":nth-child(2)")) {
                        element.addClass("slick-special-trigger")
                    }
                });
            }
        });

        $(".js-tab-slider-xs-v1").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            accessibility: false,
            variableWidth: true,
            infinite: false,
            speed: 300,
            focusOnSelect: true,
            draggable: false
        });

        $(document).on("click", ".js-tab-slider-xs-v1 .tab-list-link", function () {
            var element = $(this);

            $(".js-tab-slider-xs-v1 .tab-list-link").parent("li").removeClass("active");

            setTimeout(function () {
                element.parent("li").addClass("active")
            }, 5);

            if (element.parent("li").hasClass("slick-special-trigger")) {
                element.closest(".js-tab-slider-xs-v1").addClass("slick-special");
                element.closest(".js-tab-slider-xs-v1").removeClass("slick-last-item-active");

            } else if (element.parent("li").hasClass("slick-last-item")) {
                element.closest(".js-tab-slider-xs-v1").addClass("slick-last-item-active");

            } else {
                element.closest(".js-tab-slider-xs-v1").removeClass("slick-special");
                element.closest(".js-tab-slider-xs-v1").removeClass("slick-last-item-active");
            }
        });
    }

    if (window.matchMedia("(max-width: 991.98px)").matches) {
        $(".js-device-slider").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
            infinite: false,
            speed: 300,
            dots: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            }]
        });

        $(".js-grid-slider-md-v3").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
            infinite: false,
            speed: 300,
            responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }]
        });

        $('.js-uni-slider-md').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            speed: 300,
            dots: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        jQuery('body').tmTileSliderMD(['init-tile-slider-md-v1']);

        jQuery('body').tmTileSliderMDV2(['init-tile-slider-md-v2']);
    }

    if (window.matchMedia("(max-width: 767.98px)").matches) {
        $(".js-grid-slider-xs").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
            infinite: false,
            speed: 300,
            prevArrow: '<div class="grid-slider-navigation prev"><button type="button" class="slick-arrow slick-prev">Previous</button></div>',
            nextArrow: '<div class="grid-slider-navigation next"><button type="button" class="slick-arrow slick-next">Next</button></div>',
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
            ]
        });

        $(".js-grid-slider-xs-v1").each(function () {
            var slider = $(this);

            if (slider.hasClass("js-custom-slider")) {
                var sliderSlidesToShow = slider.attr("data-slides-to-show");
                var sliderSlidesToScroll = slider.attr("data-slides-to-scroll");

                $(".js-grid-slider-xs-v1").slick({
                    slidesToShow: sliderSlidesToShow,
                    slidesToScroll: sliderSlidesToScroll,
                    variableWidth: true,
                    infinite: false,
                    speed: 300,

                });
            } else {
                $(".js-grid-slider-xs-v1").slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true,
                    infinite: false,
                    speed: 300,
                });
            }
        })

        $(".js-tab-slider-xs-v2").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            accessibility: false,
            infinite: false,
            speed: 300,
        });

        $(".js-grid-slider-xs-v2").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
            infinite: false,
            focusOnSelect: true,
            speed: 300,
            prevArrow: '<div class="grid-slider-navigation prev"><button type="button" class="slick-arrow slick-prev">Previous</button></div>',
            nextArrow: '<div class="grid-slider-navigation next"><button type="button" class="slick-arrow slick-next">Next</button></div>',
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
            ]
        });

        $(".js-grid-slider-xs-v3").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            infinite: false,
            speed: 300,
        });

        $(".js-logo-slider-xs").slick({
            slidesToShow: 3,
            slidesToScroll: 2,
            variableWidth: true,
            infinite: false,
            dots: true,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 3000
        });

        $(".js-services-slider-xs").slick({
            slidesToShow: 3,
            slidesToScroll: 2,
            variableWidth: true,
            infinite: false,
            dots: false,
        });

        setTimeout(function () {
            $(".js-grid-slider-xs-v3").find(".slick-track").addClass("d-flex");
        }, 100);
    }
})