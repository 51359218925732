jQuery.fn.tmTargetCollapse = function () {
    return jQuery(this).find('.js-target-link').on("click", function (e) {
        e.preventDefault();
        var targetLink = $(this);
        var targetLinkList = targetLink.closest(".js-row-tablo");

        var colorTarget = targetLink.attr('data-target');

        $(colorTarget).toggleClass("is-visible");
        targetLinkList.toggleClass("is-active")
    })
}

$(function () {
    jQuery('body').tmTargetCollapse(['init-target-collapse']);
});