jQuery.fn.tmSwitchContentAttribute = function () {
    return jQuery(this).find('*[data-change-element]').each(function () {
        var element = jQuery(this);

        if (element.data("init-switch-content-attribute") !== "init") {
            $(element).on("click", function (e) {
                e.preventDefault();
                var elementData = element.data("change-element");
        
                $('*[data-change-target]').removeClass('collapsed').addClass("collapse");
        
                setTimeout(function () {
                    $('*[data-change-target = ' + elementData + ']').addClass('collapsed').removeClass("collapse");
                }, 50)
            });
        }
    });
}

$(function () {
    jQuery('body').tmSwitchContentAttribute(['init-switch-content-attribute']);
});