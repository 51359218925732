jQuery.fn.tmVideoScrollAutoPlay = function () {
    return jQuery(this).find('.js-video-autoplay').each(function () {
        var videoItem = jQuery(this);
        var source = "";
        /*
        if (videoItem.data("init-video-lazy-load") !== "init") {
            var $window = $(window);
            var itemHeight = videoItem.outerHeight();
            var itemOffset = videoItem.offset();
            var scrollGap = 0;
            var source = "";

            if (videoItem.attr("data-video-start")) {
                scrollGap = videoItem.data("video-start")
            }

            if ($window.scrollTop() > itemOffset.top && $window.scrollTop() || $window.scrollTop() >= itemOffset.top - scrollGap && !videoItem.hasClass("video-active") || videoItem.hasClass("on-load-video") && !videoItem.hasClass("video-active")) {
                if (window.matchMedia("(max-width: 767.97px)").matches) {
                    source = videoItem.attr("data-src-xs");
                } else {
                    source = videoItem.attr("data-src-md");
                }

                setTimeout(function () {
                    videoItem.get(0)

                    if (videoItem.get(0).paused) {
                        videoItem.attr("src", source).addClass("video-active");
                        videoItem.get(0).controls = false;
                        videoItem.get(0).play();
                    }
                }, 400)

            } else {
                if (videoItem.get(0).paused) {
                    videoItem.attr("src", "").removeClass("video-active")
                }
            }
        }
        */

        // Create an Intersection Observer instance
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    // The video is in view
                    if (window.matchMedia("(max-width: 767.97px)").matches) {
                        source = videoItem.attr("data-src-xs");
                    } else {
                        source = videoItem.attr("data-src-md");
                    }

                    if (videoItem.get(0).paused && !videoItem.hasClass("video-active")) {
                        // Play the video if it's paused
                        videoItem.attr("src", source).addClass("video-active");
                        videoItem.get(0).controls = false;
                        videoItem.get(0).play();
                    }
                } else {
                    videoItem.attr("src", "").removeClass("video-active")
                    
                    if (!videoItem.get(0).paused) {
                        // Pause the video if it's playing
                        videoItem.get(0).pause();
                    }
                }
            });
        });

        // Start observing the videoItem
        observer.observe(videoItem.get(0));

        // Additional code inside the plugin function can remain the same...
        // ...
    })
}

$(function () {
    $(window).on("scroll", function () {
        jQuery('body').tmVideoScrollAutoPlay(['init-video-autoplay-scroll']);
    });

    $(window).on("load", function () {
        jQuery('body').tmVideoScrollAutoPlay(['init-video-autoplay-scroll']);
    });
});