jQuery.fn.tmModalCustom = function () {
    return jQuery(this).find('.lightbox').each(function () {
        var modalLink = jQuery(this);

        if (modalLink.data("init-modal-custom") !== "init") {
            modalLink.data("init-modal-custom", "init").on("click", function (e) {
                setTimeout(function () {
                    if (modalLink.data("iframe") == true) {
                        var modalLinkCustomClass = modalLink.data("modal-custom-class")

                        setTimeout(function () {
                            $(".bootbox").addClass(modalLinkCustomClass)
                        }, 200)
                    }

                    else {
                        var dataClass = $(".bootbox .js-modal-custom").data("custom-class");
                        $(".js-modal-custom").closest(".bootbox").addClass(dataClass);
                    }
                }, 200);
            })
        }
    })
}

jQuery.fn.cleanModalLinks = function () {
    return jQuery(this).find('.js-modal-link').each(function () {
        var modals = jQuery(this);

        // Do they exist?
        if (modals.length) {
            // Loop through them
            modals.each(function () {
                // Get the current href
                var currentHref = jQuery(this).attr("href");

                // Check if there's a '#' in the href and update it
                if (currentHref && currentHref.includes('#')) {
                    var updatedHref = '#' + currentHref.split('#')[1];
                    jQuery(this).attr("href", updatedHref);
                }
            });
        }
    })
}

jQuery.fn.openModalWindow = function () {
    // Clean modal links before setting up the modals
    jQuery('body').cleanModalLinks()

    return jQuery(this).find('.js-modal-link').on("click", function (e) {
        e.preventDefault();

        // Get modal ID
        var modalId = jQuery(this).attr("href");
        var modal = jQuery("body").find(modalId);

        // Find close button
        var modalCloseBtn = modal.find(".js-modal-close");
        // Make the modal visible
        modal.css("display", "flex");
        // Set body overflow to hidden to stop scrolling behind modal
        jQuery("body").css("overflowY", "hidden");
        // Add .modal-open class to body
        jQuery("body").addClass("modal-open");

        if (!$('.js-modal-backfore').length) {
            // Create a new div element for the background/overlay if it doesn't exist
            let modalDiv = $('<div>').addClass('js-modal-backfore modal-backfore');
            // Append the div to the modal's parent or a suitable parent (in this case, it's `modal`)
            modal.after(modalDiv);
        }

        setTimeout(function() {
            $('.js-modal-backfore').addClass("active")
            modal.addClass("modal-active");
        }, 200)

        jQuery('body').tmCollapsible()

        $(".modal-wrapper").on('click', function (e) {
            // Check if the click target is outside the modal dialog
            if (!$(e.target).closest('.js-modal-dialog').length) {
                // Close the modal if the click was outside
                closeModal(modal);
            }
        });

        // Handle closing modal
        modalCloseBtn.on("click", function (e) {
            var $item = jQuery(this);
            e.preventDefault();
            closeModal(modal);

            if ($item.hasClass("js-modal-anchor")) {
                const href = $item.data('anchor');

                setTimeout(function () {
                    // SmoothScroll on id
                    mainObj.querySelector(href).scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                }, 500)
            }
        });

        function closeModal(modal) {
            modal.css("display", "none");
            jQuery("body").css("overflowY", "auto");
            modal.removeClass("modal-active");
            $('.js-modal-backfore').removeClass("active")

            setTimeout(function() {
                jQuery("body").removeClass("modal-open");
            }, 200)
        }

    })
}

$(function () {
    jQuery('body').tmModalCustom(['init-modal-custom']);

    jQuery('body').openModalWindow()
});