jQuery.fn.tmShowMoreWrap = function () {
	return jQuery(this).find('.js-show-more-button').each(function () {
		var a = jQuery(this);
		if (a.data("init-show-more-wrap") !== "init") {
			a.data("init-show-more-wrap", "init").on("click", function (e) {
				e.preventDefault();
				var btn = $(this);
				var showContent = btn.closest(".js-show-more-wrap");

				showContent.toggleClass("is-active");
				btn.closest(".show-more-button").removeClass("d-block");

				setTimeout(function () {
					var showContentHeight = showContent.find(".row-boxes").innerHeight();

					console.log(showContentHeight)

					if (showContentHeight > 5000) {
						showContent.find(".show-more-content").addClass("smart-height");
					}
				}, 500)

				if (btn.hasClass("js-no-anim")) {
					btn.closest(".show-more-button").addClass("d-block");
					btn.toggleClass("is-open");
				} else if (btn.hasClass("js-onetime")) {
					btn.closest(".show-more-button").addClass("d-none");
				} else if (btn.hasClass("js-show-section")) {
					setTimeout(function () {
						$(".js-show-more-section").removeClass("d-none");
						$(".js-show-more-section").addClass("d-block");
					}, 500)
				} else {
					setTimeout(function () {
						btn.closest(".show-more-button").addClass("d-block");
						btn.toggleClass("is-open");
					}, 700);
				}

				if (btn.hasClass("to-scroll") && btn.hasClass("is-open") || btn.hasClass("to-scroll") && btn.hasClass("smb-mp-two")) {
					setTimeout(function () {
						$('html, body').animate({
							scrollTop: $(".js-to-scroll").offset().top
						}, 500);
					}, 700);
				}

				if (btn.hasClass("js-to-bs-section") && window.matchMedia("(max-width: 767.98px)").matches) {
					setTimeout(function () {
						btn.closest(".js-bs-section").toggleClass("bg-grey-v1-xs");
						btn.closest(".js-bs-section").toggleClass("py-0");
					}, 100);
				}
			})
		}
	})
}

jQuery.fn.tmShowMoreWrapMultiPos = function () {
	return jQuery(this).find('.js-smb-multi-position').each(function () {
		var a = jQuery(this);
		if (a.data("init-show-more-wrap-multi-pos") !== "init") {
			a.data("init-show-more-wrap-multi-pos", "init").on("click", function (e) {
				e.preventDefault();
				var btn = $(this);

				if (!btn.hasClass("smb-mp-two")) {
					setTimeout(function () {
						$("html").addClass("show-smb-mp-two")
					}, 300)
				} else if (btn.hasClass("smb-mp-two")) {
					setTimeout(function () {
						$("html").removeClass("show-smb-mp-two")
					}, 300)
				}
			})
		}
	})
}

jQuery.fn.tmCollapseScroll = function () {
	return jQuery(this).find('.js-collapse-scroll').each(function () {
		var a = jQuery(this);
		if (a.data("init-collapse-scroll") !== "init") {
			a.data("init-collapse-scroll", "init").on("click", function (e) {
				e.preventDefault();
				var btn = $(this);
				var btnTarget = btn.attr("href");

				setTimeout(function () {
					$([document.documentElement, document.body]).animate({
						scrollTop: $(btnTarget).offset().top
					}, 1000);
				}, 200)
			})
		}
	})
}

jQuery.fn.tmShowMoreText = function () {
	return jQuery(this).find('.js-show-more-text').on("click", function (e) {
		var btn = $(this);
		var targetText = btn.attr("href");
		var btnClosestWrap = btn.closest(".js-smt-wrap")

		$(targetText).collapse('toggle');
		btn.toggleClass("is-active");
		btnClosestWrap.toggleClass("is-active");
	})
}

$(function () {
	jQuery('body').tmShowMoreWrap(['init-show-more-wrap']);
	jQuery('body').tmShowMoreWrapMultiPos(['init-show-multi-pos']);
	jQuery('body').tmCollapseScroll(['init-collapse-scroll']);
	jQuery('body').tmShowMoreText(['init-show-more-text']);
});