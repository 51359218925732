jQuery.fn.tmProgressBarAnim = function () {
    return jQuery(this).find('.js-progress-bar-in').each(function () {
        var element = jQuery(this);

        if (element.data("init-progress-bar-anim") !== "init") {
            var closestSection = element.closest(".bs-section")
            var wscroll = 0;
            var wscroll = $(window).scrollTop();

            var value = element.attr('aria-valuenow');
            var valueMax = element.attr('aria-valuemax');

            var animDuration = element.attr("data-animationDuration");

            if (wscroll >= closestSection.offset().top && element.width() === 0) {
                if (value !== undefined) {
                    element.animate({
                        width: (value + '%')
                    }, animDuration * 1000)
                }

                setTimeout(function(){
                    if(element.css("width") >= valueMax) {
                        element.stop(true);
                    }
                }, animDuration * 970)
            }

            $(".js-reset-counters").on("click", function(e){
                e.preventDefault();

                element.stop(true)

                setTimeout(function() {
                    element.width(0)
                }, 500);

                setTimeout(function() {
                    if (value !== undefined) {
                        element.animate({
                            width: (value + '%')
                        }, animDuration * 1000)
                    }

                    setTimeout(function(){
                        if(element.css("width") >= valueMax) {
                            element.stop(true);
                        }
                    }, animDuration * 970)
                }, 2000)
            });
        }
    })
}

$(function () {
    jQuery('body').tmProgressBarAnim(['init-progress-bar-anim']);

    $(window).on('scroll', function () {
        jQuery('body').tmProgressBarAnim(['init-progress-bar-anim']);
    })
});