jQuery.fn.tmValidateInputV1 = function () {
    return jQuery(this).find('.js-validate-input-v1').each(function () {
        var element = jQuery(this);

        if (element.data("init-validate-input-v1") !== "init") {

            function clearValidationMessage(element) {
                element.closest(".form-group").removeClass("form-error-v2 has-error").find(".form-error").remove();
            }

            function setValidationMessage(element, event) {
                var formGroupDiv = element.closest(".form-group").addClass("form-error-v2 has-error");

                var errorMessage = element.attr("data-error-message");
                if (event.target.validity.valueMissing) {
                    errorMessage = element.attr("data-required-error-message");
                }
                formGroupDiv.append('<p class="form-error">' + errorMessage + '</p>');
            }

            element.on("invalid", function (event) {
                event.preventDefault();
                clearValidationMessage($(this));
                setValidationMessage($(this), event);
            });
            element.on("valid", function () {
                clearValidationMessage($(this));
            });
        }
    })
}

$(function () {
    jQuery('body').tmValidateInputV1(['init-validate-input-v1']);
});