
// kontrola zda se pouzivaji jen cookies z cocowebu
var checkAllowCookies = function checkAllowCookies() {

    var getCookies = function getCookies() {
      var ca = document.cookie;
      var res = [];
      var cookies = ca.split(';');
      var cookie;
      while (cookie = cookies.shift()) {
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        var spl = cookie.indexOf('=');
        res.push({
          name: cookie.slice(0, spl),
          value: cookie.slice(spl + 1, cookie.length)
        });
      }
      return res;
    };
    var getConsentUrl = function getConsentUrl() {
      try {
        var url = new URL(window.gcConfig.cocosDelegate);
        return 'https://' + url.host;
      } catch (e) {
        throw new Error('window.gcConfig not defined!');
      }
    };
    var loadAllowCookies = new Promise(function (resolve, reject) {
      var url = getConsentUrl();
      if (!url) {
        return;
      }
  
      fetch(url + '/dialog/recipients').then(function (response) {
        if (!response.ok) {
          reject('bad response');
        }
        return response.json();
      })
      .then(function (json) {
        var res = [];
        try {
          json.forEach(function (el) {
            if (el.cookies) {
              el.cookies.forEach(function (itm) {
                var reg = '^(t|t2|t3|p|)' + itm.name + '$'
  
                if (itm.name.startsWith('*')) {
                  reg = '^.*\'' + itm.name.replace('*', '') + '$'
                } else if (itm.name.endsWith('*')) {
                  reg = '^(t|t2|t3|p|)' + itm.name.replace('*', '') + '.*$'
                }
                
                res.push(reg);
              });
            }
          });
          resolve(res);
        } catch (e) {
          reject(e);
        }
      });
  
    });
  
    loadAllowCookies.then(function (allowCookies) {
      var locCookies = getCookies();
      var userAgent = window.navigator.userAgent;
      var pageUrl = new URL(window.location.href);
      var reportUrl = getConsentUrl() + '/report';
      var none = locCookies.filter(function (itm) {   
        return !allowCookies.some(function (reg) {
          var reg = new RegExp(reg);
          return reg.test(itm.name);
        });
      });
  
      none.forEach(function (itm) {
        var formData = new FormData();
        formData.append('userAgent', userAgent);
        formData.append('pageUrl', pageUrl.protocol + '//' + pageUrl.host + pageUrl.pathname);
        formData.append('cookieName', itm.name);
        formData.append('cookieValue', itm.value);
        fetch(reportUrl, {
          body: formData,
          method: "post"
        });
      });
    });
  };
  
