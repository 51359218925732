jQuery.fn.tmModalCollapseLink = function () {
    return jQuery(this).find('.js-collapse-link').each(function () {
        var link = jQuery(this);

        if (link.data("init-modal-collapse-link") !== "init") {
            $(document).on("click", ".js-collapse-link", function (e) {
                e.preventDefault();
                var element = $(this);

                element.toggleClass("collapsed");
            })
        }
    })
}

$(window).on("load", function () {
    jQuery('body').tmClickAnchorWithFocus(['init-modal-collapse-link']);
});