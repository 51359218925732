jQuery.fn.tmResizableImageWidth = function () {
    return jQuery(this).find('.js-resizable').each(function () {
        var imageObject = jQuery(this);

        if (imageObject.data("init-resizable-image-width") !== "init") {
            if (window.matchMedia("(min-width: 1200px)").matches) {
                var elementXL = imageObject.data("width-xl");
                imageObject.attr('width', elementXL);
            }

            if (window.matchMedia("(max-width: 1199px)").matches) {
                var elementLG = imageObject.data("width-lg");
                imageObject.attr('width', elementLG);
            }

            if (window.matchMedia("(max-width: 991px)").matches) {
                var elementMD = imageObject.data("width-md");
                imageObject.attr('width', elementMD);

                var elementHeightMD = imageObject.data("height-md");
                imageObject.attr('height', elementHeightMD);
            }

            if (window.matchMedia("(max-width: 767.97px)").matches) {
                var elementXs = imageObject.data("width-xs");
                imageObject.attr('width', elementXs);

                var elementHeightXs = imageObject.data("height-xs");
                imageObject.attr('height', elementHeightXs);
            }
        }
    })
}

$(function () {
    jQuery('body').tmResizableImageWidth(['init-resizable-image-width']);
});

$(window).on("resize", function () {
    jQuery('body').tmResizableImageWidth(['init-resizable-image-width']);
});